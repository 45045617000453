.study-container {
  position: relative;
  img {
    user-select: none;
  }
  min-height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #ebf6ff;
  .back-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 271px;
    background: linear-gradient(0deg, #ebf6ff 0%, #c4edff 100%);
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-top: 4px;
    &-left {
      position: absolute;
      top: 52px;
      left: 31px;
      &-text {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-icon {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .left-circular {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: linear-gradient(90deg, #5872ff 0%, #2552ff 100%);
          z-index: 10;
        }
        .right-circular {
          position: relative;
          left: -5px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: linear-gradient(90deg, #90a1ff 0%, #b9c8ff 100%);
          z-index: 9;
        }
      }
    }
    &-right {
      width: 260px;
    }
  }
  .center {
    position: relative;
    margin: 3px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 37px 0 32px 0;
    background-color: #fff;
    border-radius: 13px;
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #666666;
      line-height: 26px;
      :global(.rv-icon) {
        position: relative;
        top: 2px;
        font-size: 18px;
      }
    }
    &-add {
      margin-top: -20px;
      font-size: 16px;
      color: #333333;
    }
    &-phone {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      color: #333333;
    }
    &-code {
      user-select: all;
      margin-top: 31px;
      margin-bottom: 8px;
      width: 185px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 18px;
    padding: 26px 53px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 13px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .item-icon {
        margin-bottom: 18px;
        width: 48px;
        height: 48px;
      }
      .item-text {
        font-size: 14px;
        color: #333333;
      }
    }
  }
}

@primary-color: #1DA57A;