.mine-container {
  img {
    user-select: none;
  }
  position: relative;
  padding: 0 18px;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  overflow-y: overlay;
  height: calc(100vh - 80px);
  height: calc(100vh - 80px - env(safe-area-inset-bottom));
  box-sizing: border-box;
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
    z-index: 1;
  }
  .mine-header {
    position: relative;
    margin-top: 79px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
    &-avatar {
      flex-shrink: 0;
      width: 92px;
      height: 92px;
      object-fit: cover;
      border-radius: 46px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 19px;
      height: 92px;
      .content-name {
        color: #202244;
        font-size: 22px;
        font-weight: 500;
      }
      .content-id {
        font-size: 14px;
        color: #333333;
      }
      .content-operate {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        &-icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .mine-integral {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 24px;
    width: 100%;
    height: 92px;
    // aspect-ratio: 4.3;
    background-image: url("/images/mine/ic_back.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    &-item {
      flex: 1;
      text-align: center;
      flex-shrink: 0;
      .item-hour {
        font-weight: bold;
        &-text {
          // color: #fff;
          color: #ffd581;
          font-size: 20px;
        }
        &-unit {
          color: #ffd581;
          font-size: 15px;
        }
        &-integral {
          color: #ffd581;
          font-size: 20px;
        }
        .hour {
          margin-left: 0.1rem;
          color: #ffd581;
        }
      }
      .item-desc {
        margin-top: 4px;
        color: #bababa;
        font-size: 13px;
      }
    }
  }
  .mine-jump {
    position: relative;
    top: -12px;
    padding-top: 12px;
    padding-bottom: 20px;
    // height: 226px;
    background-color: #fff;
    border-radius: 0px 0px 18px 18px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin-top: 20px;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-icon {
        width: 46px;
        height: 46px;
      }
      .item-text {
        margin-top: 18px;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .mine-option {
    margin-top: 19px;
    margin-bottom: 20px;
    padding: 22px 12px 13px 18px;
    border-radius: 18px;
    background-color: #fff;
    &-item {
      &:not(:last-child) {
        margin-bottom: 9px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      .item-left {
        display: flex;
        align-items: center;
        &-icon {
          width: 35px;
          height: 35px;
        }
        &-title {
          margin-left: 9px;
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .item-right {
        width: 48px;
        height: 48px;
      }
    }
  }
  .dialog-container {
    font-size: 14px;
    color: #333333;
    &-next {
      margin: 10px 0;
      text-decoration: underline;
      font-size: 16px;
    }
  }
  .report-share {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background-color: rgba(32, 34, 68, 0.8);
    &-top {
      position: relative;
      margin-top: 5vh;
      display: block;
      img {
        display: block;
        margin: 0 auto;
        height: 55vh;
        border-radius: 15px;
      }
      .close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
      }
    }
    .invite-report-button {
      margin: 5vh auto 0;
      width: 230px;
      height: 7vh;
      border-radius: 22px;
      background: linear-gradient(0deg, #fdd91b 0%, #fffb1e 100%);
      box-shadow: 0px 3px 0px 0px #fdb11f;
      color: #a85512;
      font-size: 20px;
      font-weight: bold;
      line-height: 7vh;
      text-align: center;
    }
    &-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20vh;
      background-color: #fff;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      .button-list {
        margin-top: 3vh;
        padding: 0 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 6vh;
            height: 6vh;
          }
          .text {
            margin-top: 9px;
            color: #000000;
            font-size: 12px;
            text-align: center;
          }
          .save {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6vh;
            height: 6vh;
            border-radius: 5vh;
            background-color: rgba(255, 148, 71, 1);
            &-icon {
              width: 3vh;
              height: 3vh;
            }
          }
        }
      }
      .cancel-button {
        margin-top: 2vh;
        color: rgba(143, 143, 143, 1);
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

@primary-color: #1DA57A;