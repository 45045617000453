.ssc-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.8);
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22%;
    background-image: url("../../images/game/ssc/bg-top.png");
    background-size: 100% 100%;
  }
  .bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36%;
    background-image: url("../../images/game/ssc/bg-bottom.png");
    background-size: 100% 100%;
  }
  .top-title {
    position: absolute;
    top: 0;
    left: calc(50% - 128.5px);
    width: 257px;
    height: 189px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/game/ssc/title.png");
  }
  .teacher-bg {
    position: absolute;
    top: 250px;
    left: 0;
    width: 86px;
    height: 99px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/game/ssc/teacher-bg.png");
    &-avator {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 62px;
      height: 62px;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 50%;
      user-select: none;
    }
    &-text {
      position: absolute;
      left: 8px;
      bottom: 8px;
      color: #ffffff;
      font-size: 17px;
      font-weight: bold;
      text-shadow: 0px 1px 0px rgba(20, 86, 228, 0.56);
    }
  }
  .student-bg {
    position: absolute;
    top: 75%;
    right: 0;
    width: 348px;
    height: 99px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/game/ssc/student-bg.png");
    &-list {
      display: flex;
      position: absolute;
      top: 13px;
      left: 12px;
      .list-item {
        box-sizing: border-box;
        margin-right: 13px;
        width: 73px;
        height: 73px;
        background-size: 100%;
        background-repeat: no-repeat;
        &--select {
          border: 4px solid #e43828;
          border-radius: 13px;
        }
      }
      .stone {
        background-image: url("../../images/game/ssc/stone-select.png");
      }
      .scissor {
        background-image: url("../../images/game/ssc/scissor-select.png");
      }
      .cloth {
        background-image: url("../../images/game/ssc/cloth-select.png");
      }
    }
    &-text {
      position: absolute;
      right: 8px;
      bottom: 8px;
      color: #ffffff;
      font-size: 17px;
      font-weight: bold;
      text-shadow: 0px 1px 0px rgba(255, 72, 56, 0.56);
    }
    &-avator {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 62px;
      height: 62px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      box-sizing: border-box;
      user-select: none;
    }
  }
  .animation-wrapper {
    margin-top: 350px;
    display: flex;
    justify-content: center;
    width: 100%;
    animation: show_hide 3s linear forwards;
    &-item {
      height: 120px;
      user-select: none;
      &:nth-child(1) {
        transform: rotate(90deg);
        animation: hand_shake_left 0.6s ease-in-out forwards 3;
      }
      &:nth-child(3) {
        transform: rotate(-90deg) scaleX(-1);
        animation: hand_shake_right 0.6s ease-in-out forwards 3;
      }
    }
    &-center {
      margin: 0 27px;
      font-size: 55px;
      font-weight: bold;
      color: #fff;
    }
  }
  .result-wrapper {
    animation: end_scale 1s linear forwards;
    &-wl {
      display: block;
      margin: 284px auto 0;
      width: 95px;
      user-select: none;
    }
    &-u {
      display: block;
      margin: 53px auto 0;
      height: 194px;
      user-select: none;
    }
  }
  @keyframes show_hide {
    0%,
    100% {
      opacity: 0;
    }
    20%,
    80% {
      opacity: 1;
    }
  }
  @keyframes hand_shake_left {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(55deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
  @keyframes hand_shake_right {
    0% {
      transform: rotate(-90deg) scaleX(-1);
    }
    50% {
      transform: rotate(-55deg) scaleX(-1);
    }
    100% {
      transform: rotate(-90deg) scaleX(-1);
    }
  }
  @keyframes end_scale {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .bg-top {
      height: 25%;
      background-image: url("../../images/game/ssc/bg-top-pad.png");
    }
    .bg-bottom {
      height: 35%;
      background-image: url("../../images/game/ssc/bg-bottom-pad.png");
    }
    .animation-wrapper{
      margin-top: 30%;
    }

    .result-wrapper {
      animation: end_scale 1s linear forwards;
      &-wl {
        display: block;
        margin: 210px auto 0;
        width: 95px;
        user-select: none;
      }
      &-u {
        display: block;
        margin: 10px auto 0;
        height: 194px;
        user-select: none;
      }
    }
  }

  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .bg-top {
      height: 34%;
      background-image: url("../../images/game/ssc/bg-top.png");
    }
    .bg-bottom {
      height: 45%;
      background-image: url("../../images/game/ssc/bg-bottom.png");
    }
  }
}

@primary-color: #1DA57A;