.info-container {
  img {
    user-select: none;
  }
  padding: 0 18px;
  position: relative;
  min-height: 100vh;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  .common-back {
    position: absolute;
    top: 52px;
    left: 13px;
    width: 48px;
    height: 48px;
  }
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
  }
  .login-tab {
    padding-top: 139px;
    margin-left: 7px;
  }
  .info-avatar {
    margin-top: 35px;
    padding: 0 18px 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 108px;
    border-radius: 13px;
    background-color: #fff;
    &-input {
      position: absolute;
      top: 13px;
      left: 13px;
      width: 250px;
      height: 81px;
      opacity: 0;
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .content-img {
        width: 81px;
        height: 81px;
        object-fit: cover;
        border-radius: 13px;
        flex-shrink: 0;
      }
      .content-text {
        margin-left: 18px;
        color: #333333;
        font-size: 20px;
      }
    }
    &-arrow {
      width: 48px;
      height: 48px;
    }
  }
  .info-common-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 18px;
    height: 64px;
    background-color: #fff;
    border-radius: 13px;
    .cell-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-icon {
        width: 35px;
        height: 35px;
      }
      &-input {
        width: 200px;
        margin-left: 9px;
        font-size: 20px;
        color: #333333;
        border: none;
        font-family: monospace;
        &::placeholder {
          font-size: 20px;
          color: #333333;
        }
      }
      &-text {
        margin-left: 9px;
        font-size: 20px;
        color: #333333;
      }
    }
    .cell-arrow {
      width: 48px;
      height: 48px;
    }
  }
  .login-submit {
    margin-top: 79px;
  }
  :global(.rv-picker__confirm) {
    color: #5872ff;
  }
}

@primary-color: #1DA57A;