.teacher-top {
  position: relative;
  .teacher-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow-y: hidden;
    overflow-x: overlay;
    @media screen and (orientation: portrait) and (max-width: 767px) {
      &-item {
        margin-right: 7px;
        width: calc((100% - 14px) / 3);
      }
      .rv-image__img {
        margin: 0 auto;
        width: 65%;
      }
    }
    @media screen and (orientation: portrait) and (min-width: 768px) {
      &-item {
        margin-right: 10px;
        width: 140px;
        height: 140px;
      }
      .rv-image {
        height: 70%;
      }
      .rv-image__img {
        margin: 0 auto;
        width: auto;
        height: 100%;
      }
    }
    @media screen and (orientation: landscape) {
      &-item {
        margin-right: 10px;
        width: 140px;
        height: 140px;
      }
      .rv-image {
        height: 70%;
      }
      .rv-image__img {
        margin: 0 auto;
        width: auto;
        height: 100%;
      }
    }
    &-item {
      flex-shrink: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      border-radius: 13px;
      .center-text {
        box-sizing: border-box;
        padding: 8px 9px 14px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-name {
          max-width: 120px;
          line-height: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #202244;
          font-size: 14px;
        }
        .teacher-title-icon {
          margin-left: 12px;
          flex-shrink: 0;
          width: 22px;
          height: 22px;
          .icon-content {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;