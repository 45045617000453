.InviteBox {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-size: 100% auto;
  background-image: url("/assets/images/invite/back.jpg");
  background-repeat: no-repeat;
  .title1 {
    margin-top: 60px;
    width: 70%;
  }
  .q100 {
    margin-top: 100px;
    width: 90%;
  }
  .userInfoBox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .back-img {
      top: 0;
      width: 100%;
    }
    .rv-form {
      padding: 30px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .formItem {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.a {
        justify-content: center;
        img {
          width: 100%;
        }
        .rv-button {
          border: none;
        }
      }
      .xy {
        white-space: nowrap;
        margin-right: 12px;
        transform: translateY(-1px);
        color: var(--color6);
      }
      .tagA {
        display: inline-block;
        white-space: nowrap;
        color: var(--primyColor2);
        &.a {
          margin-left: 12px;
        }
      }
      .rv-cell {
        padding: 0;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        .rv-input__control {
          border: 1px solid var(--color8);
          box-sizing: border-box;
          padding: 6px 10px;
          border-radius: 12px;
        }
        .rv-cell__value {
          position: relative;
          .rv-field__error-message {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }
    }
  }
  .tsImg {
    width: 100%;
  }
  .kcImg {
    width: 100%;
  }
  .hxlImg {
    width: 100%;
  }
  .code {
    margin-bottom: 20px;
    width: 80%;
  }
}

@primary-color: #1DA57A;