.LabelCom_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .LabelCom_box_title {
    color: var(--color1);
    font-weight: 600;
  }
  .LabelCom_box_link {
    color: var(--title_h3_color);
    font-size: 12px;
  }
}

@primary-color: #1DA57A;