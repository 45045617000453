@media only screen and (max-width: 500px) {
  .report-container {
    background-color: #d3e3ff;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .share-button {
      width: 19px;
      height: 19px;
    }
    .report-share {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 500;
      background-color: rgba(32, 34, 68, 0.8);
      &-top {
        position: relative;
        margin-top: 5vh;
        display: block;
        img {
          display: block;
          margin: 0 auto;
          height: 55vh;
          border-radius: 15px;
        }
        .close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
        }
      }
      .invite-report-button {
        margin: 5vh auto 0;
        width: 230px;
        height: 7vh;
        border-radius: 22px;
        background: linear-gradient(0deg, #fdd91b 0%, #fffb1e 100%);
        box-shadow: 0px 3px 0px 0px #fdb11f;
        color: #a85512;
        font-size: 20px;
        font-weight: bold;
        line-height: 7vh;
        text-align: center;
      }
      &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        background-color: #fff;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        .button-list {
          margin-top: 3vh;
          padding: 0 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon {
              width: 6vh;
              height: 6vh;
            }
            .text {
              margin-top: 9px;
              color: #000000;
              font-size: 12px;
              text-align: center;
            }
            .save {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 6vh;
              height: 6vh;
              border-radius: 5vh;
              background-color: rgba(255, 148, 71, 1);
              &-icon {
                width: 3vh;
                height: 3vh;
              }
            }
          }
        }
        .cancel-button {
          margin-top: 2vh;
          color: rgba(143, 143, 143, 1);
          font-size: 14px;
          text-align: center;
        }
      }
    }
    .hd {
      margin: 0 auto;
      position: relative;
      width: 428px;
      height: 325px;
      background-image: url("/images/report/hd-bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-id {
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 10px;
        color: #fff;
      }
      &-black {
        margin: 0 auto;
        width: 331px;
        height: 174px;
        background-image: url("/images/report/black-bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        &-course {
          margin: 66px auto 0;
          font-size: 33px;
          &--title {
            color: #fff220;
          }
        }
        &-text {
          position: relative;
          margin: 9px auto 0;
          font-size: 16px;
          font-weight: bold;
          .color-1 {
            margin-left: 10px;
            color: #fff220;
          }
          &--name {
            font-size: 34px;
          }
          &-diamond {
            &--left {
              width: 13px;
              margin-right: 10px;
              vertical-align: text-top;
            }
            &--right {
              width: 13px;
              margin-left: 10px;
              vertical-align: text-top;
            }
          }
        }
      }
      &-duck {
        position: absolute;
        top: 145px;
        left: 24px;
        width: 79px;
        user-select: none;
      }
      &-ubao {
        position: absolute;
        top: 102px;
        right: 0;
        width: 146px;
        user-select: none;
      }
      &-hello {
        position: absolute;
        top: 180px;
        left: 103px;
        width: 62px;
      }
    }
    .bd {
      margin: 0 auto;
      position: relative;
      overflow-y: hidden;
      margin-top: -48px;
      width: 428px;
      // height: 500px;
      background-image: url("/images/report/bd-bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-el-container {
        position: relative;
        top: -48px;
        .report {
          margin: 0 auto;
          width: 394px;
          height: 280px;
          background-image: url("/images/report/body-report-bg.png");
          background-size: 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          &-title {
            position: relative;
            top: 3px;
            color: #ffffff;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
          }
          &-block {
            margin: 31px auto 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 360px;
            .block {
              padding-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              width: 114px;
              height: 130px;
              background-size: 100%;
              background-repeat: no-repeat;
            }
            .block-vocabulary {
              .block();
              color: #507fff;
              background-image: url("/images/report/vocabulary-bg.png");
              &-num {
                font-size: 34px;
                font-weight: 900;
              }
              &-unit {
                font-size: 15px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
            .block-sentence {
              .block();
              color: #ffb516;
              background-image: url("/images/report/sentence-bg.png");
              &-num {
                font-size: 34px;
                font-weight: 900;
              }
              &-unit {
                font-size: 15px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
            .block-grade {
              .block();
              color: #06a4ab;
              background-image: url("/images/report/grade-bg.png");
              &-num {
                font-size: 24px;
                font-weight: 900;
              }
              &-unit {
                font-size: 34px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
          }
          &-schedule {
            margin: -15px auto 0;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            color: #064265;
          }
          &-progress {
            margin: 8px auto 0;
            width: 360px;
            background-color: #f3f3f3;
            border-radius: 9px;
            .bar {
              height: 17px;
              background-color: #ffc200;
              border-radius: 9px;
            }
          }
        }
        .connect-container {
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin: 0 auto;
          width: 394px;
          z-index: 100;
          .mark {
            position: absolute;
            top: -25px;
            width: 27px;
            user-select: none;
          }
          .mark-left {
            .mark();
            left: 31px;
          }
          .mark-right {
            .mark();
            right: 31px;
          }
        }
        .percentage {
          position: relative;
          margin: 10px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-center {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 49px auto 41px;
            width: 218px;
            height: 218px;
            background-image: url("/images/report/percentage-center.png");
            background-size: 100%;
            background-repeat: no-repeat;
            &-ubao {
              width: 90px;
              user-select: none;
            }
          }
          .line {
            position: absolute;
            user-select: none;
          }
          &-line-1 {
            .line();
            width: 96px;
            top: 78px;
            left: 48px;
          }
          &-line-2 {
            .line();
            width: 94px;
            top: 97px;
            right: 48px;
          }
          &-line-3 {
            .line();
            width: 84px;
            left: 49px;
            bottom: 88px;
          }
          &-line-4 {
            .line();
            width: 95px;
            right: 47px;
            bottom: 66px;
          }
          .round {
            position: absolute;
            width: 6px;
            height: 6px;
            user-select: none;
          }
          &-round-1 {
            .round();
            left: 47px;
            top: 76px;
          }
          &-round-2 {
            .round();
            right: 47px;
            top: 95px;
          }
          &-round-3 {
            .round();
            left: 48px;
            bottom: 86px;
          }
          &-round-4 {
            .round();
            right: 47px;
            bottom: 65px;
          }
          .icon {
            position: absolute;
            user-select: none;
          }
          &-icon-1 {
            .icon();
            width: 13px;
            top: 70px;
            left: 31px;
          }
          &-icon-2 {
            .icon();
            width: 18px;
            top: 91px;
            right: 26px;
          }
          &-icon-3 {
            .icon();
            width: 18px;
            left: 27px;
            bottom: 80px;
          }
          &-icon-4 {
            .icon();
            width: 18px;
            right: 26px;
            bottom: 59px;
          }
          .desc {
            position: absolute;
            color: #0961f5;
            text-align: center;
            .title {
              font-size: 16px;
              font-weight: bold;
            }
            .text {
              font-size: 13px;
              font-weight: bold;
            }
            .fill {
              color: #ffc200;
            }
          }
          &-text-1 {
            .desc();
            top: 35px;
            left: 50px;
          }
          &-text-2 {
            .desc();
            top: 52px;
            right: 52px;
          }
          &-text-3 {
            .desc();
            bottom: 95px;
            left: 40px;
          }
          &-text-4 {
            .desc();
            bottom: 72px;
            right: 45px;
          }
        }
        .classroom {
          position: relative;
          padding: 25px 0 47px;
          margin: 18px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-title {
            display: block;
            margin: 0 auto;
            width: 116px;
            user-select: none;
          }
          &-head {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 26px auto 0;
            width: 360px;
            height: 39px;
            color: #064265;
            font-size: 14px;
            font-weight: 500;
            border-radius: 11px;
            background-color: #f4fafe;
            &-me {
              margin-left: 17px;
            }
            &-teacher {
              margin-left: 76px;
            }
            &-eval {
              margin-left: 78px;
            }
          }
          &-item {
            margin: 11px auto 0;
            padding: 17px 0;
            width: 360px;
            background-color: #f4fafe;
            border-radius: 11px;
            &-word {
              margin: 0 17px;
              color: #064265;
              font-size: 16px;
              font-weight: 900;
              word-wrap: break-word;
              line-height: 20px;
            }
            &-medium {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 38px;
              &-comment {
                margin-left: 19px;
                color: #064265;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
        .foreign {
          position: relative;
          padding: 25px 0 17px;
          margin: 18px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-title {
            display: block;
            margin: 0 auto;
            width: 116px;
            user-select: none;
          }
          &-body {
            padding: 17px 18px;
            margin: 20px auto 0;
            width: 324px;
            border-radius: 11px;
            background-color: #f4fafe;
            &-head {
              position: relative;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              .avatar {
                width: 74px;
                user-select: none;
              }
              .name {
                margin-bottom: 12px;
                width: 120px;
                user-select: none;
              }
              .text {
                position: absolute;
                top: 39px;
                left: 80px;
                color: #ff7d1e;
                font-size: 16px;
                font-weight: 900;
                line-height: 21px;
              }
            }
            &-ch {
              padding-bottom: 17px;
              margin-top: 17px;
              font-size: 14px;
              font-weight: bold;
              color: #064265;
              line-height: 21px;
              word-break: break-all;
              border-bottom: 1px dashed #4bb7ff;
            }
            &-en {
              margin-top: 17px;
              font-size: 14px;
              font-weight: bold;
              color: #064265;
              line-height: 21px;
              // word-break: break-all;
            }
          }
        }
      }
    }
    .rv-nav-bar__title {
      font-size: 21px;
    }
    .rv-nav-bar__content {
      padding-top: 0;
    }
    .rv-skeleton__content {
      margin-top: 20px;
    }
    .rv-nav-bar {
      padding-top: 52px;
      // padding-top: constant(safe-area-inset-top);
    }
  }
  .recommend-tip {
    position: fixed;
    top: 260px;
    right: 30px;
    width: 150px;
    height: 152px;
    background-image: url("/images/report/recommend.png");
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 200;
    border-radius: 5px;
    &-text {
      position: absolute;
      bottom: 32px;
      width: 100%;
      text-align: center;
      font-size: 10px;
      color: #fff;
    }
    &-buy {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
    }
    &-hide {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
    }
  }
}
@media screen and (min-width: 501px) {
  .report-container {
    .share-button {
      width: 19px;
      height: 19px;
    }
    .report-share {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 500;
      background-color: rgba(32, 34, 68, 0.8);
      &-top {
        position: relative;
        margin-top: 5vh;
        display: block;
        img {
          display: block;
          margin: 0 auto;
          height: 55vh;
          border-radius: 15px;
        }
        .close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
        }
      }
      .invite-report-button {
        margin: 5vh auto 0;
        width: 230px;
        height: 7vh;
        border-radius: 22px;
        background: linear-gradient(0deg, #fdd91b 0%, #fffb1e 100%);
        box-shadow: 0px 3px 0px 0px #fdb11f;
        color: #a85512;
        font-size: 20px;
        font-weight: bold;
        line-height: 7vh;
        text-align: center;
      }
      &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        background-color: #fff;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        .button-list {
          margin-top: 3vh;
          padding: 0 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon {
              width: 6vh;
              height: 6vh;
            }
            .text {
              margin-top: 9px;
              color: #000000;
              font-size: 12px;
              text-align: center;
            }
            .save {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 6vh;
              height: 6vh;
              border-radius: 5vh;
              background-color: rgba(255, 148, 71, 1);
              &-icon {
                width: 3vh;
                height: 3vh;
              }
            }
          }
        }
        .cancel-button {
          margin-top: 2vh;
          color: rgba(143, 143, 143, 1);
          font-size: 14px;
          text-align: center;
        }
      }
    }
    .rv-nav-bar {
      padding-top: 52px;
      // padding-top: constant(safe-area-inset-top);
    }
    background-color: #d3e3ff;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: constant(safe-area-inset-bottom);
    /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom);
    /*兼容 IOS>11.2*/
    .hd {
      margin: 0 auto;
      position: relative;
      width: 428px;
      height: 325px;
      background-image: url("/images/report/hd-bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-id {
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 10px;
        color: #fff;
      }
      &-black {
        margin: 0 auto;
        width: 331px;
        height: 174px;
        background-image: url("/images/report/black-bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        &-course {
          margin: 66px auto 0;
          font-size: 33px;
          &--title {
            color: #fff220;
          }
        }
        &-text {
          position: relative;
          margin: 9px auto 0;
          font-size: 16px;
          font-weight: bold;
          .color-1 {
            margin-left: 10px;
            color: #fff220;
          }
          &-diamond {
            &--left {
              width: 13px;
              margin-right: 10px;
              vertical-align: text-top;
            }
            &--right {
              width: 13px;
              margin-left: 10px;
              vertical-align: text-top;
            }
          }
        }
      }
      &-duck {
        position: absolute;
        top: 145px;
        left: 24px;
        width: 79px;
        user-select: none;
      }
      &-ubao {
        position: absolute;
        top: 102px;
        right: 0;
        width: 146px;
        user-select: none;
      }
      &-hello {
        position: absolute;
        top: 180px;
        left: 103px;
        width: 62px;
      }
    }
    .bd {
      margin: 0 auto;
      position: relative;
      overflow-y: hidden;
      margin-top: -48px;
      width: 428px;
      // height: 500px;
      background-image: url("/images/report/bd-bg.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-el-container {
        position: relative;
        top: -48px;
        .report {
          margin: 0 auto;
          width: 394px;
          height: 280px;
          background-image: url("/images/report/body-report-bg.png");
          background-size: 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          &-title {
            position: relative;
            top: 3px;
            color: #ffffff;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
          }
          &-block {
            margin: 31px auto 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 360px;
            .block {
              padding-top: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              width: 114px;
              height: 130px;
              background-size: 100%;
              background-repeat: no-repeat;
            }
            .block-vocabulary {
              .block();
              color: #507fff;
              background-image: url("/images/report/vocabulary-bg.png");
              &-num {
                font-size: 37px;
                font-weight: 900;
              }
              &-unit {
                font-size: 15px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
            .block-sentence {
              .block();
              color: #ffb516;
              background-image: url("/images/report/sentence-bg.png");
              &-num {
                font-size: 37px;
                font-weight: 900;
              }
              &-unit {
                font-size: 15px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
            .block-grade {
              .block();
              color: #06a4ab;
              background-image: url("/images/report/grade-bg.png");
              &-num {
                font-size: 27px;
                font-weight: 900;
              }
              &-unit {
                font-size: 37px;
                font-weight: 900;
              }
              &-name {
                margin-top: 5px;
                font-size: 15px;
                font-weight: 400;
              }
            }
          }
          &-schedule {
            margin: -15px auto 0;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            color: #064265;
          }
          &-progress {
            margin: 8px auto 0;
            width: 360px;
            background-color: #f3f3f3;
            border-radius: 9px;
            .bar {
              height: 17px;
              background-color: #ffc200;
              border-radius: 9px;
            }
          }
        }
        .connect-container {
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin: 0 auto;
          width: 394px;
          z-index: 100;
          .mark {
            position: absolute;
            top: -25px;
            width: 27px;
            user-select: none;
          }
          .mark-left {
            .mark();
            left: 31px;
          }
          .mark-right {
            .mark();
            right: 31px;
          }
        }
        .percentage {
          position: relative;
          margin: 10px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-center {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 49px auto 41px;
            width: 218px;
            height: 218px;
            background-image: url("/images/report/percentage-center.png");
            background-size: 100%;
            background-repeat: no-repeat;
            &-ubao {
              width: 90px;
              user-select: none;
            }
          }
          .line {
            position: absolute;
            user-select: none;
          }
          &-line-1 {
            .line();
            width: 96px;
            top: 78px;
            left: 48px;
          }
          &-line-2 {
            .line();
            width: 94px;
            top: 97px;
            right: 48px;
          }
          &-line-3 {
            .line();
            width: 84px;
            left: 49px;
            bottom: 88px;
          }
          &-line-4 {
            .line();
            width: 95px;
            right: 47px;
            bottom: 66px;
          }
          .round {
            position: absolute;
            width: 6px;
            height: 6px;
            user-select: none;
          }
          &-round-1 {
            .round();
            left: 47px;
            top: 76px;
          }
          &-round-2 {
            .round();
            right: 47px;
            top: 95px;
          }
          &-round-3 {
            .round();
            left: 48px;
            bottom: 86px;
          }
          &-round-4 {
            .round();
            right: 47px;
            bottom: 65px;
          }
          .icon {
            position: absolute;
            user-select: none;
          }
          &-icon-1 {
            .icon();
            width: 13px;
            top: 70px;
            left: 31px;
          }
          &-icon-2 {
            .icon();
            width: 18px;
            top: 91px;
            right: 26px;
          }
          &-icon-3 {
            .icon();
            width: 18px;
            left: 27px;
            bottom: 80px;
          }
          &-icon-4 {
            .icon();
            width: 18px;
            right: 26px;
            bottom: 59px;
          }
          .desc {
            position: absolute;
            color: #0961f5;
            text-align: center;
            .title {
              font-size: 16px;
              font-weight: bold;
            }
            .text {
              font-size: 13px;
              font-weight: bold;
            }
            .fill {
              color: #ffc200;
            }
          }
          &-text-1 {
            .desc();
            top: 35px;
            left: 50px;
          }
          &-text-2 {
            .desc();
            top: 52px;
            right: 52px;
          }
          &-text-3 {
            .desc();
            bottom: 95px;
            left: 40px;
          }
          &-text-4 {
            .desc();
            bottom: 72px;
            right: 45px;
          }
        }
        .classroom {
          position: relative;
          padding: 25px 0 47px;
          margin: 18px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-title {
            display: block;
            margin: 0 auto;
            width: 116px;
            user-select: none;
          }
          &-head {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 26px auto 0;
            width: 360px;
            height: 39px;
            color: #064265;
            font-size: 14px;
            font-weight: 500;
            border-radius: 11px;
            background-color: #f4fafe;
            &-me {
              margin-left: 17px;
            }
            &-teacher {
              margin-left: 76px;
            }
            &-eval {
              margin-left: 78px;
            }
          }
          &-item {
            margin: 11px auto 0;
            padding: 17px 0;
            width: 360px;
            background-color: #f4fafe;
            border-radius: 11px;
            &-word {
              margin: 0 17px;
              color: #064265;
              font-size: 16px;
              font-weight: 900;
              word-wrap: break-word;
              line-height: 20px;
            }
            &-medium {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 38px;
              &-comment {
                margin-left: 19px;
                color: #064265;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
        .foreign {
          position: relative;
          padding: 25px 0 17px;
          margin: 18px auto 0;
          width: 394px;
          background-color: #ffffff;
          border-radius: 23px;
          box-shadow: 0 8px 0 -1px #1a66cc;
          overflow: hidden;
          &-title {
            display: block;
            margin: 0 auto;
            width: 116px;
            user-select: none;
          }
          &-body {
            padding: 17px 18px;
            margin: 20px auto 0;
            width: 324px;
            border-radius: 11px;
            background-color: #f4fafe;
            &-head {
              position: relative;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              .avatar {
                width: 74px;
                user-select: none;
              }
              .name {
                margin-bottom: 12px;
                width: 120px;
                user-select: none;
              }
              .text {
                position: absolute;
                top: 39px;
                left: 80px;
                color: #ff7d1e;
                font-size: 16px;
                font-weight: 900;
                line-height: 21px;
              }
            }
            &-ch {
              padding-bottom: 17px;
              margin-top: 17px;
              font-size: 14px;
              font-weight: bold;
              color: #064265;
              line-height: 21px;
              word-break: break-all;
              border-bottom: 1px dashed #4bb7ff;
            }
            &-en {
              margin-top: 17px;
              font-size: 14px;
              font-weight: bold;
              color: #064265;
              line-height: 21px;
              // word-break: break-all;
            }
          }
        }
      }
    }
    .rv-nav-bar__title {
      font-size: 21px;
    }
    .rv-nav-bar__content {
      padding-top: 0;
    }
    .rv-skeleton__content {
      margin-top: 20px;
    }
  }
  .recommend-tip {
    position: fixed;
    top: 260px;
    right: 30px;
    width: 150px;
    height: 152px;
    background-image: url("/images/report/recommend.png");
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 200;
    border-radius: 5px;
    &-text {
      position: absolute;
      bottom: 32px;
      width: 100%;
      text-align: center;
      font-size: 10px;
      color: #fff;
    }
    &-buy {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
    }
    &-hide {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
    }
  }
}

@primary-color: #1DA57A;