.web-url-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  .web-url-iframe {
    margin-top: 52px;
    position: relative;
    border: none;
    height: calc(100vh - 52px);
  }
}

@primary-color: #1DA57A;