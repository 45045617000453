.not-found-container {
    text-align: center;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 5px;
    max-width: 400px;
  }
  
  .not-found-container h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .not-found-container a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
@primary-color: #1DA57A;