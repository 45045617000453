.my-product {
  width: 100vw;
  height: 100vh;
  background: #dbdfff;
  box-sizing: border-box;
  overflow-y: overlay;
  .rv-nav-bar__title {
    font-size: 21px;
  }
  .my-product-context {
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1rem;

    .productList {
      width: 100%;
      height: 100%;
      overflow: overlay;
      .productItemBox {
        margin-top: 1rem;
        border-radius: 0.6rem;
        background-color: #fff;
        padding: 0.6rem;
        box-sizing: border-box;
        .btn-wrapper {
          display: flex;
        }
        .btn {
          margin-left: 12px;
          width: 5rem;
          height: 1.8rem;
          line-height: 1.8rem;
          box-sizing: border-box;
          border: 1px solid var(--primyColor1);
          color: var(--primyColor1);
          border-radius: 1rem;
          text-align: center;
          font-size: 0.8rem;
        }
        .top {
          display: flex;
          align-items: flex-start;
          img {
            width: 6.2rem;
            aspect-ratio: 1;
            border-radius: 1rem;
          }
          .top_right {
            flex: 1;
            width: 0;
            margin-left: 0.6rem;
            .default() {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: var(--color4);
              font-size: 0.8rem;
              margin-top: 0.4rem;
            }
            .top_right_title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              color: var(--color2);
              font-weight: 600;
              height: 2.44rem;
              line-height: 1.26rem;
            }
            .top_right_orderCode {
              .default();
            }

            .top_right_count {
              .default();
            }
          }
        }
        .orderClose {
          color: var(--primyColor6);
          margin-top: 0.6rem;
          font-size: 0.8rem;
          padding: 0.2rem;
          background: linear-gradient(
            270deg,
            rgba(255, 248, 240, 0) 0%,
            #fff8f0 100%
          );
          display: flex;
          align-items: center;
          img {
            width: 0.8rem;
            aspect-ratio: 1;
            margin-right: 0.2rem;
            transform: translateY(0.1rem);
          }
        }
        .default() {
          margin-top: 0.4rem;
          .unitPrice {
            font-size: 0.8rem;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--color2);
          .msg {
            font-weight: 600;
          }
          .default();
          &.dzf {
            .msg {
              color: var(--primyColor8);
            }
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;