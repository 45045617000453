.pop-view {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pop-img {
    object-fit: contain;
    height: 40%;
    max-width: 100%;
  }
  .pop-close {
    margin-top: 43px;
    width: 44px;
  }
}

@primary-color: #1DA57A;