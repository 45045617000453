.pop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .success-pop-wrapper {
    width: 305px;
    height: 358px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("/images/image/lesson-start.png");
    &-title {
      margin-top: 70px;
      margin-left: 50px;
      font-size: 26px;
      font-weight: bold;
      color: rgba(0, 107, 152, 1);
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-left: 30px;
      padding: 0px 6px;
      box-sizing: border-box;
      height: 128px;
      width: 252px;
      color: rgba(0, 107, 152, 1);
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background-color: rgba(40, 168, 222, 0.1);
      border-radius: 6px;
      &__color1 {
        color: rgba(255, 139, 35, 1);
      }
    }
    &-button {
      margin: 20px auto 0;
      width: 240px;
      display: flex;
      justify-content: space-between;
      .button-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 47px;
        background-size: 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-size: 16px;
      }
      .button-cancel {
        background-image: url("/images/image/start-lesson-cancel.png");
      }
      .button-confirm {
        background-image: url("/images/image/start-lesson-confirm.png");
      }
    }
  }
  .fail-pop-wrapper {
    width: 317px;
    height: 367px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("/images/image/lesson-start-fail.png");
    &-title {
      margin-top: 70px;
      margin-left: 50px;
      font-size: 26px;
      font-weight: bold;
      color: rgba(0, 107, 152, 1);
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      margin-left: 30px;
      padding: 0px 6px;
      box-sizing: border-box;
      width: 252px;
      height: 106px;
      color: rgba(0, 107, 152, 1);
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      background-color: rgba(40, 168, 222, 0.1);
      border-radius: 6px;
    }
    &-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 18px auto 0;
      width: 137px;
      height: 49px;
      background-image: url("/images/image/fail-lesson-confirm.png");
      background-size: 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .modal-close {
    margin-top: 31px;
    width: 24px;
    height: 24px;
  }
}

@primary-color: #1DA57A;