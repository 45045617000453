.login-input-container {
  img {
    user-select: none;
  }
  &--selected {
    border: 1px solid #1955ff;
    box-shadow: 0px 7px 13px 0px rgba(25, 85, 255, 0.2);
  }
  border: 1px solid #fff;
  height: 64px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 13px;
  .left-content {
    display: flex;
    align-items: center;
    &-icon {
      width: 35px;
      height: 35px;
    }
    &-input {
      width: 180px;
      margin-left: 9px;
      border: none;
      font-size: 20px;
      color: #333333;
      // font-family: monospace;
      // letter-spacing: 2px;
      &::placeholder {
        font-size: 20px;
        color: #333333;
      }
    }
  }
  .right-content {
    width: 48px;
    height: 48px;
  }
}

@primary-color: #1DA57A;