.login-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 61px;
  background: linear-gradient(90deg, #5872ff 0%, #2552ff 100%);
  border-radius: 13px;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  &--disabled {
    background: #cccccc;
  }
}

@primary-color: #1DA57A;