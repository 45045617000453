.game-start-container {
  img {
    user-select: none;
  }
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/game/gamestart/background.png");
  font-family: JostSemiBold;
  .top-text {
    position: absolute;
    top: 152px;
    left: 50%;
    transform: translate(-50%);
    text-shadow: 0px 2px 4px rgba(255, 255, 255, 1);
    font-size: 36px;
    font-weight: bold;
    color: rgba(255, 195, 0, 1);
    animation: revealText 1.5s linear forwards;
    white-space: nowrap;
    z-index: 200;
  }
  @keyframes revealText {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }
  .center-text {
    position: absolute;
    top: 215px;
    left: calc(50% - 155px);
    width: 311px;
    height: 91px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/game/gamestart/button.png");
    z-index: 200;
    span {
      display: block;
    }
    &-wave {
      position: relative;
      left: 10px;
      margin-top: 23px;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      white-space: nowrap;
      color: rgba(255, 255, 255, 1);
      span {
        display: inline-block;
        animation: wave 0.6s ease-in-out infinite;
      }
      span:nth-child(1) {
        animation-delay: 0s;
      }
      span:nth-child(2) {
        animation-delay: 0.1s;
      }
      span:nth-child(3) {
        animation-delay: 0.2s;
      }
      span:nth-child(4) {
        animation-delay: 0.3s;
      }
      span:nth-child(5) {
        animation-delay: 0.4s;
      }
      span:nth-child(6) {
        animation-delay: 0.5s;
      }
    }
  }
  @keyframes wave {
    0%,
    100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
  }
  .balloon-1 {
    position: absolute;
    bottom: -50%;
    left: -50%;
    height: 300px;
    z-index: 100;
    animation: float_1 2s linear forwards;
  }
  .balloon-2 {
    position: absolute;
    bottom: -50%;
    left: -25%;
    height: 300px;
    z-index: 100;
    animation: float_2 2s linear forwards 1s;
  }
  .balloon-3 {
    position: absolute;
    bottom: -50%;
    right: -25%;
    height: 300px;
    z-index: 100;
    animation: float_3 3s linear forwards 1.5s;
  }
  @keyframes float_1 {
    from {
      bottom: -50%;
      left: -50%;
    }
    to {
      bottom: 100%;
      left: -25%;
    }
  }
  @keyframes float_2 {
    from {
      bottom: -50%;
      left: -25%;
    }
    to {
      bottom: 100%;
      left: 0;
    }
  }
  @keyframes float_3 {
    from {
      bottom: -50%;
      right: -25%;
    }
    to {
      bottom: 100%;
      right: 0;
    }
  }
}

@primary-color: #1DA57A;