.flip-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.8);
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background-image: url("../../images/game/flip/bg-top.png");
    background-size: 100% 100%;
  }
  .bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: url("../../images/game/flip/bg-bottom.png");
    background-size: 100% 100%;
  }
  .top-title {
    position: absolute;
    top: -12%;
    left: calc(50% - 163.5px);
    width: 327px;
    height: 269px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/game/flip/title.png");
    &-text {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .card-list {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 50% auto 0;
    width: calc(100% - 20px);
    &-item {
      position: relative;
      width: 129px;
      height: 208px;
      transform-style: preserve-3d;
      transition: 1s ease-in-out;
      .common-card {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      .front {
        user-select: none;
      }
      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 208px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/flip/card-back.png");
        transform: rotateY(180deg);
        &-score {
          width: 90px;
        }
      }
      &--flip {
        transform: rotateY(180deg);
      }
    }
    &--hide {
      animation: card_hide 1s linear 2s forwards;
    }
  }
  .finger {
    position: absolute;
    bottom: -30px;
    left: 37px;
    width: 55px;
    user-select: none;
    animation: finger_animation 1.2s linear forwards infinite alternate;
    &--hide {
      opacity: 0;
      transition: 0.5s all linear;
    }
  }
  .end-bg {
    position: absolute;
    top: 40%;
    left: calc(50% - 127px);
    width: 254px;
    user-select: none;
    animation: end_scale 1s linear forwards;
  }
  @keyframes card_hide {
    form {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes finger_hide {
    0% {
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
  @keyframes finger_animation {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(300px);
    }
  }
  @keyframes end_scale {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .top-title {
      top:-20%;
    }
    .bg-top {
      height: 80%;
      background-image: url("../../images/game/flip/bg-top-pad.png");
    }
    .bg-bottom {
      height: 30%;
      background-image: url("../../images/game/flip/bg-bottom-pad.png");
    }
    .card-list {
      margin: 30% auto 0;
      &-item {
        width: 100px;
        height: 150px;
      }
      .back {
        width: 100px;
        height: 150px;
        &-score {
          width: 70px;
        }
      }
    }
    .finger {
      position: absolute;
      bottom: -30px;
      left: 37px;
      width: 55px;
      user-select: none;
      animation: finger_animation 1.2s linear forwards infinite alternate;
      &--hide {
        opacity: 0;
        transition: 0.5s all linear;
      }
    }
    .end-bg {
      position: absolute;
      top: 30%;
      left: calc(50% - 100px);
      width: 200px;
      user-select: none;
      animation: end_scale 1s linear forwards;
    }
    @keyframes finger_animation {
      from {
        transform: translateX(120px);
      }
      to {
        transform: translateX(400px);
      }
    }
  }

  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .top-title {
      top:-20%;
    }
    .bg-top {
      height: 80%;
    }
    .bg-bottom {
      height: 30%;
    }
    .card-list{
      margin: 40% auto 0;
    }
    .end-bg {
      position: absolute;
      top: 30%;
      left: calc(50% - 100px);
      width: 200px;
      user-select: none;
      animation: end_scale 1s linear forwards;
    }
    @keyframes finger_animation {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(279px);
      }
    }
  }
}

@primary-color: #1DA57A;