.textbook-container {
  position: relative;
  overflow-y: overlay;
  height: 100vh;
  @media screen and (orientation: portrait) and (max-width: 768px) {
    .list-box {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 80vw;
      .top-data {
        &-pic {
          margin: 0 auto;
          display: block;
          width: 80vw;
          border-radius: 8px;
        }
        &-text {
          height: 27px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: bold;
          color: #3d3d3d;
          line-height: 27px;
        }
      }
      .vertical-item {
        width: 35vw;
        margin-bottom: 10px;
        &--hasTop {
          &:nth-child(odd) {
            margin-left: 10vw;
          }
        }
        &--noTop {
          &:nth-child(even) {
            margin-left: 10vw;
          }
        }
        &-pic {
          display: block;
          width: 35vw;
          border-radius: 8px;
        }
        &-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          text-align: center;
          margin-top: 5px;
          font-size: 15px;
          font-weight: bold;
          color: #3d3d3d;
          line-height: 27px;
        }
      }
      .bottom {
        margin-bottom: 80px;
        margin-bottom: calc(80px + env(safe-area-inset-bottom));
        width: 100%;
        height: 40px;
        line-height: 30px;
        color: #999999;
        font-size: 13px;
        text-align: center;
      }
    }
  }
  @media screen and (orientation: portrait) and (min-width: 768px) {
    .list-box {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 80vw;
      .top-data {
        &-pic {
          margin: 0 auto;
          display: block;
          width: 80vw;
          border-radius: 8px;
        }
        &-text {
          height: 27px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: bold;
          color: #3d3d3d;
          line-height: 27px;
        }
      }
      .vertical-item {
        width: 17vw;
        margin-bottom: 10px;
        &--hasTop {
          &:not(:nth-child(4n-2)) {
            margin-left: 4vw;
          }
        }
        &--noTop {
          &:not(:nth-child(4n-3)) {
            margin-left: 4vw;
          }
        }
        &-pic {
          margin: 0 auto;
          display: block;
          width: 17vw;
          border-radius: 8px;
        }
        &-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          text-align: center;
          margin-top: 5px;
          font-size: 15px;
          font-weight: bold;
          color: #3d3d3d;
          line-height: 27px;
        }
      }
      .bottom {
        margin-bottom: 80px;
        margin-bottom: calc(80px + env(safe-area-inset-bottom));
        width: 100%;
        height: 40px;
        line-height: 30px;
        color: #999999;
        font-size: 13px;
        text-align: center;
      }
    }
  }
  @media screen and (orientation: landscape) {
    .list-box {
      margin: 0 auto;
      width: 94vw;
      display: grid;
      grid-template-columns: repeat(7, 10vw);
      grid-column-gap: 4vw;
      .top-data {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
        &-pic {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: calc(100% - 30px);
          object-fit: contain;
          border-radius: 8px;
        }
        &-text {
          height: 27px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          height: 30px;
          text-align: center;
          font-size: 12px;
          color: #202244;
          line-height: 30px;
        }
      }

      .vertical-item {
        position: relative;
        &-pic {
          width: 100%;
          border-radius: 8px;
        }
        &-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          width: 100%;
          height: 30px;
          text-align: center;
          font-size: 12px;
          color: #202244;
          line-height: 30px;
        }
      }
      .bottom {
        grid-column-start: 1;
        grid-column-end: 8;
        margin-bottom: 80px;
        margin-bottom: calc(80px + env(safe-area-inset-bottom));
        width: 100%;
        height: 40px;
        line-height: 30px;
        color: #999999;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

@primary-color: #1DA57A;