.goods-detail-container {
  img {
    user-select: none;
  }
  background-color: #f5f5f5;
  height: 100vh;
  overflow: auto;
  .goods-pic {
    width: 100%;
  }
  .goods-desc {
    padding: 0px 13px 123px 13px;
    &-block {
      margin-top: 13px;
      padding: 13px;
      border-radius: 13px;
      background-color: #fff;
      .block-text {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .block-integral {
        margin-top: 9px;
        color: #ed1919;
        font-size: 22px;
        font-weight: bold;
      }
      .block-title {
        color: #333333;
        font-size: 15px;
        font-weight: bold;
      }
      .block-details {
        margin-top: 9px;
        color: #333333;
        font-size: 14px;
      }
      .block-content {
        width: 100%;
      }
    }
  }
  .button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 9px 18px;
    width: 100%;
    height: 110px;
    background-color: #fff;
    box-sizing: border-box;
  }
  .address-pop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 110px;
    width: 100%;
    height: calc(100vh - 110px);
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    &-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      padding: 22px 18px 0px 18px;
      width: 100%;
      height: 329px;
      box-sizing: border-box;
      border-radius: 18px 18px 0px 0px;
      background-color: #fff;
      .content-header {
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }
        &-add {
          display: flex;
          align-items: center;
          .add-icon {
            margin-right: 9px;
            width: 18px;
            height: 18px;
          }
          .add-text {
            color: #1955ff;
            font-size: 15px;
          }
        }
      }
      .content-body {
        flex: 1;
        overflow: auto;
      }
      .content-item {
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 101px;
        box-sizing: border-box;
        border-radius: 13px;
        border: 1px solid #979797;
        padding: 13px;
        font-size: 15px;
        &-left {
          .left-title {
            display: flex;
            align-items: center;
            &-name {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              max-width: 200px;
              color: #333333;
              font-weight: bold;
            }
            &-phone {
              margin-left: 9px;
              color: #666666;
            }
          }
          .left-address {
            margin-top: 9px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            color: #333333;
            line-height: 22px;
          }
        }
        .common-content-right {
          flex-shrink: 0;
          width: 26px;
          height: 26px;
          margin-left: 13px;
          box-sizing: border-box;
        }
        &-right {
          .common-content-right();
          border-radius: 13px;
          border: 1px solid #aaaaaa;
        }
        &-right--icon {
          .common-content-right();
        }
        &--selected {
          border-color: #1955ff;
        }
      }
      .add-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 101px;
        box-sizing: border-box;
        border-radius: 13px;
        border: 1px solid #1955ff;
        &-icon {
          width: 18px;
          height: 18px;
        }
        &-text {
          margin-left: 10px;
          color: #1955ff;
          font-size: 15px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;