.douyin-weixin-container {
  height: 100vh;
  overflow-y: overlay;
  padding: 45px 15px;
  background-color: rgb(19, 55, 254);
  .douyin-box {
    padding: 41px 0px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 0px rgba(74, 74, 74, 1);
    border-radius: 12px;
    .step-wrapper {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      &-text {
        margin-top: 10px;
        color: #333333;
        font-size: 20px;
      }
      &-button {
        width: 175px;
      }
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-desc {
        position: relative;
        top: -8px;
        color: #333333;
        font-size: 10px;
      }
    }
    .video-box {
      margin: 27px auto;
      width: 90%;
      height: 269px;
      border-radius: 8px;
      background: linear-gradient(270deg, #fff, #c8d0ff);
      overflow: hidden;
      &-title {
        margin-top: 24px;
        margin-left: 24px;
        color: #333333;
        font-size: 16px;
      }
    }
    .step-pic--1 {
      display: block;
      margin: 0 auto;
      width: 95%;
    }
    .step-pic--3 {
      display: block;
      margin: 0 auto 39px;
      width: 95%;
    }
    .step-pic--4 {
      display: block;
      margin: 0 auto;
      width: 70%;
    }
  }
}

@primary-color: #1DA57A;