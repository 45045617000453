.points-detail-container {
  img {
    user-select: none;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 18px;
  height: 100vh;
  background: linear-gradient(0, #f4f6ff 0%, #ccd2ff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 188px;
  }
  .points-header {
    margin-top: 9px;
    margin-left: 10px;
    &-title {
      font-size: 52px;
      font-weight: bold;
      color: #000000;
    }
    &-text {
      margin-left: 9px;
      font-size: 16px;
      font-weight: bold;
      color: #666666;
    }
  }
  .points-list {
    margin-top: 9px;
    padding-bottom: 9px;
    flex: 1;
    overflow-y: auto;
    &-detail {
      padding: 13px 22px;
      background-color: #fff;
      border-radius: 13px;
      .detail-title {
        padding-bottom: 13px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .detail-item {
        padding: 14px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          text-align: left;
          .left-title {
            font-size: 15px;
            color: #333333;
          }
          .left-text {
            margin-top: 6px;
            font-size: 13px;
            color: #999999;
          }
        }
        &-right {
          text-align: right;
          .right-title {
            font-size: 16px;
            font-weight: bold;
            color: #ff8700;
          }
          .right-text {
            margin-top: 6px;
            font-size: 13px;
            color: #999999;
          }
        }
      }
    }
    &-bottom {
      width: 100%;
      height: 40px;
      line-height: 30px;
      color: #999999;
      font-size: 13px;
      text-align: center;
    }
  }
}

@primary-color: #1DA57A;