.intro-test-container {
  img {
    user-select: none;
  }
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
  }
  .page-1 {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 64%;
      .content-top {
        &-icon {
          margin: 0 auto;
          width: 200px;
        }
        &-title {
          margin-top: 25px;
          font-size: 48px;
          font-weight: bold;
          color: #000;
        }
      }
      .content-bottom {
        &-start {
          margin-bottom: 29px;
          width: 251px;
          height: 61px;
          border-radius: 20px;
          background-color: rgba(42, 130, 228, 1);
          color: #fff;
          line-height: 61px;
          font-size: 30px;
          text-align: center;
        }
        &-end {
          font-size: 18px;
          color: rgb(56, 56, 56);
          text-align: center;
        }
      }
    }
  }
  .exit-button {
    position: absolute;
    top: 52px;
    right: 20px;
    color: #fff;
    text-align: center;
    width: 90px;
    height: 35px;
    line-height: 35px;
    background-color: rgba(42, 130, 228, 1);
    border-radius: 5px;
  }
  .page-2 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 100px 24px 67px;
    box-sizing: border-box;

    &-content {
      padding: 41px 33px;
      flex: 1;
      border-radius: 20px;
      background-color: #fff;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: overlay;
      .title {
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        &--select {
          color: rgba(128, 128, 128, 1);
        }
      }
      .option {
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 12px;
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(229, 229, 229, 0.46);
        border-radius: 5px;
        font-size: 18px;
        color: rgba(128, 128, 128, 1);
        &-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-select {
          position: relative;
          flex-shrink: 0;
          margin-right: 16px;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-radius: 4px;
          background-color: rgba(42, 130, 228, 1);
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 8px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            top: 2px;
            left: 6px;
            transform: rotate(35deg);
          }
        }
        &-unselect {
          flex-shrink: 0;
          margin-right: 16px;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid rgba(196, 196, 196, 1);
        }
        &:last-child {
          margin-bottom: 0;
        }
        &-input {
          width: 140px;
          border: none;
          border-bottom: 1px dashed rgba(128, 128, 128, 1);
          background-color: transparent;
        }
      }
      .start-button {
        margin: 50px auto 0;
        width: 250px;
        height: 61px;
        border-radius: 20px;
        line-height: 61px;
        text-align: center;
        background-color: rgba(42, 130, 228, 1);
        color: #fff;
        font-size: 30px;
      }
    }
  }
  .page-3 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 100px 24px 67px;
    box-sizing: border-box;

    &-content {
      padding: 41px 33px;
      flex: 1;
      border-radius: 20px;
      background-color: #fff;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: overlay;
      .title {
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;
        height: 64px;
        padding: 16px 18px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: rgba(229, 229, 229, 0.46);
        border: 1px solid rgba(229, 229, 229, 0.46);
        &--select {
          border: 1px solid rgba(42, 130, 228, 1);
          background-color: rgba(42, 130, 228, 0.11);
        }
        &:last-child {
          margin-bottom: 0;
        }
        &-left {
          display: flex;
          align-items: center;
          &-item {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: rgba(204, 204, 204, 1);
            font-size: 18px;
            font-weight: bold;
            color: rgba(42, 130, 228, 1);
          }
          &-text {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 190px;
            margin-left: 22px;
            font-size: 18px;
            color: rgba(128, 128, 128, 1);
          }
        }
        &-select {
          flex-shrink: 0;
          position: relative;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: rgba(42, 130, 228, 1);
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 8px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            top: 2px;
            left: 5px;
            transform: rotate(35deg);
          }
        }
        &-unselect {
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid rgba(196, 196, 196, 1);
        }
      }
      .right-text {
        margin-top: 154px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(212, 48, 48, 1);
        text-align: center;
      }
      .inAdvance-button {
        margin: 16px auto 0;
        width: 161px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        color: #fff;
        border-radius: 15px;
        background-color: rgba(194, 233, 251, 1);
      }
    }
  }
  .page-4 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 100px 24px 67px;
    box-sizing: border-box;
    &-content {
      flex: 1;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: overlay;
      .title {
        margin-top: 66px;
        text-align: center;
        font-size: 48px;
        font-weight: bold;
        color: #1955ff;
      }
      .center {
        display: block;
        margin: 40px auto 0;
        width: 150px;
        height: 156px;
      }
      .next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 79px auto 0;
        width: 170px;
        height: 73px;
        background-size: 100% auto;
        background-image: url("/images/image/start-lesson-confirm.png");
        background-repeat: no-repeat;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .page-5 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 100px 24px 0px;
    box-sizing: border-box;

    &-content {
      padding: 12px 25px 25px;
      flex: 1;
      border-radius: 20px;
      background: linear-gradient(
        180deg,
        rgba(245, 247, 250, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: overlay;
      .title {
        text-align: center;
        color: rgba(140, 140, 141, 1);
        font-size: 30px;
        font-weight: bold;
      }
      .level {
        margin: 13px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 67px;
        border-radius: 20px;
        background: linear-gradient(
          180deg,
          rgba(232, 239, 255, 1) 0%,
          rgba(212, 223, 255, 1) 100%
        );
        color: rgba(42, 130, 228, 1);
        font-size: 20px;
        font-weight: bold;
      }
      .report-img {
        margin-top: 8px;
        width: 100%;
      }
      .report-text {
        margin-top: 22px;
        font-size: 16px;
        line-height: 24px;
        color: rgba(128, 128, 128, 1);
      }
      .intro-title {
        margin: 26px auto 0;
        width: 238px;
        height: 49px;
        line-height: 49px;
        color: #fff;
        font-size: 24px;
        text-align: center;
        border-radius: 15px;
        background: linear-gradient(
          135.96deg,
          rgba(139, 120, 255, 1) 0%,
          rgba(84, 81, 214, 1) 100%
        );
      }
      .intro-text {
        margin: 16px auto 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 275px;
        height: 49px;
        line-height: 49px;
        box-sizing: border-box;
        border-radius: 15px;
        border: 2px solid rgba(22, 96, 214, 1);
        font-size: 18px;
        font-weight: bold;
        color: rgba(42, 130, 228, 1);
        text-align: center;
      }
    }
  }
}
.end-pop {
  width: 265px;
  height: 311px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("/images/image/lesson-start.png");
  &-title {
    margin-top: 60px;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 107, 152, 1);
  }
  &-content {
    margin: 45px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 219px;
    height: 92px;
    color: rgba(255, 139, 35, 1);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    background-color: rgba(40, 168, 222, 0.1);
    border-radius: 6px;
  }
  &-buttons {
    margin: 24px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 205px;
    .button-confirm {
      width: 95px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background: linear-gradient(
        180deg,
        rgba(211, 211, 211, 1) 0%,
        rgba(166, 166, 166, 0.51) 26.41%,
        rgba(166, 166, 166, 0.76) 78.99%,
        rgba(166, 166, 166, 1) 100%
      );
    }
    .button-cancel {
      width: 95px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      text-align: center;
      color: rgba(255, 141, 26, 1);
      font-size: 16px;
      font-weight: bold;
      background: linear-gradient(
        180deg,
        rgba(255, 235, 59, 1) 0%,
        rgba(255, 235, 59, 0.51) 26.41%,
        rgba(255, 235, 59, 0.76) 78.99%,
        rgba(255, 235, 59, 1) 99.97%
      );
    }
  }
}

@primary-color: #1DA57A;