.global() {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  left: 50%;
}
.box {
  width: 100%;
  height: 100%;
  background: linear-gradient(#5973ff 0%, #edf4ff 100%);
  position: relative;
  .box_1 {
    .global();
    top: 7%;
    width: 80%;
  }
  .box_2 {
    .global();
    top: 24%;
    width: 90%;
  }
  .box_3 {
    .global();
    top: 33%;
    width: 40%;
  }
  .box_4 {
    .global();
    left: 34%;
    top: 52.8%;
    width: 33%;
    > img {
      width: 100%;
    }
    .name {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      color: rgb(253, 119, 35);
    }
  }
  .box_5 {
    .global();
    left: 70%;
    top: 52.8%;
    width: 33%;
    > img {
      width: 100%;
    }
    .name {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      color: rgb(253, 119, 35);
    }
  }
  .box_6 {
    .global();
    left: 88%;
    top: 76%;
    width: 27%;
    z-index: 1;
  }
  .box_7 {
    .global();
    left: 13%;
    top: 81%;
    width: 41%;
  }
  .box_8 {
    .global();
    left: 94%;
    top: 3%;
    width: 18%;
    z-index: 1;
  }
  .box_9 {
    .global();
    left: 2%;
    top: 17%;
    width: 18%;
    z-index: 1;
  }
}

@primary-color: #1DA57A;