.doll-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.8);
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-image: url("../../images/game/doll/bg-top.png");
    background-size: 100% 100%;
  }
  .bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-image: url("../../images/game/doll/bg-bottom.png");
    background-size: 100% 100%;
  }
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 32%;
    left: 0;
    width: 100%;
    &-mask {
      position: absolute;
      top: -5px;
      margin:0 auto;
      width: 415px;
      user-select: none;
      z-index: 200;
    }
    &-center {
      position: relative;
      margin: 0 auto;
      width: 402px;
      height: 357px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../images/game/doll/machine.png");
      z-index: 100;
      overflow: hidden;
      &-list {
        margin: 95px auto 0;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        &--hide {
          opacity: 0;
          transition: 1s all linear;
        }
        .ubao {
          &-top {
            position: relative;
            &--select1 {
              animation: ubao_up 0.5s linear 0.5s forwards;
            }
            &--select3 {
              animation: ubao_up 0.5s linear 1.5s forwards;
            }
            &-doll {
              position: relative;
              height: 80px;
              user-select: none;
            }
            &-bg {
              position: absolute;
              top: -10px;
              left: calc(50% - 50px);
              width: 100px;
              user-select: none;
              opacity: 0;
              &--select1 {
                animation:
                        light_show 1s linear 0.5s forwards,
                        light_scale 0.3s linear 1s infinite alternate;
              }
              &--select3 {
                animation:
                        light_show 1s linear 1.5s forwards,
                        light_scale 0.3s linear 1.5s infinite alternate;
              }
            }
            z-index: 200;
          }
          &-bottom {
            position: relative;
            bottom: 5px;
            display: block;
            margin: 0 auto;
            width: 63px;
            user-select: none;
            z-index: 100;
          }
        }
        &-hanger {
          position: absolute;
          left: 50px;
          top: -5px;
          width: 29px;
          user-select: none;
          &--move-1 {
            animation: ubao_1 1s linear forwards;
          }
          &--move-2 {
            animation:
                    ubao_2_horizontal 1s linear forwards,
                    ubao_2_vertical 1s linear 1s forwards;
          }
          &--move-3 {
            animation:
                    ubao_3_horizontal 1s linear forwards,
                    ubao_3_vertical 1s linear 1s forwards;
          }
          &--move-4 {
            animation:
                    ubao_4_horizontal 1s linear forwards,
                    ubao_4_vertical 1s linear 1s forwards;
          }
          &--move-common {
            animation: ubao_common 4s linear forwards;
          }
        }
      }
      &-result {
        display: block;
        margin: 44px auto 0;
        height: 150px;
        user-select: none;
        animation: result_show 1s linear forwards;
      }
      &-button {
        position: absolute;
        top: 218px;
        right: 30px;
        width: 116px;
        height: 44px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/doll/button-start.png");
        animation: flicker 0.3s linear infinite alternate;
        &--disabled {
          background-image: url("../../images/game/doll/button-start-disabled.png");
          animation: none;
        }
      }
    }
  }
  @keyframes flicker {
    from {
      transform: scale(0.96);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes ubao_common {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(280px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes ubao_1 {
    0%,
    100% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(25px);
    }
  }
  @keyframes ubao_2_horizontal {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(90px, 0);
    }
  }
  @keyframes ubao_2_vertical {
    0% {
      transform: translate(90px, 0);
    }
    50% {
      transform: translate(90px, 25px);
    }
    100% {
      transform: translate(90px, 0);
    }
  }
  @keyframes ubao_3_horizontal {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(175px, 0);
    }
  }
  @keyframes ubao_3_vertical {
    0% {
      transform: translate(175px, 0);
    }
    50% {
      transform: translate(175px, 25px);
    }
    100% {
      transform: translate(175px, 0);
    }
  }
  @keyframes ubao_4_horizontal {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(280px, 0);
    }
  }
  @keyframes ubao_4_vertical {
    0% {
      transform: translate(280px, 0);
    }
    50% {
      transform: translate(280px, 25px);
    }
    100% {
      transform: translate(280px, 0);
    }
  }
  @keyframes ubao_up {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-25px);
    }
  }
  @keyframes light_show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes light_scale {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1.2);
    }
  }
  @keyframes result_show {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .bg-top {
      height: 75%;
      background-image: url("../../images/game/doll/bg-top-pad.png");
    }
    .bg-bottom {
      height: 26%;
      background-image: url("../../images/game/doll/bg-bottom-pad.png");
    }
    .machine{
      top:15%;
    }
  }
}

@primary-color: #1DA57A;