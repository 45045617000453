.feedback-container {
  img {
    user-select: none;
  }
  padding: 0 18px;
  position: relative;
  height: 100vh;
  overflow-y: overlay;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
  }
  .login-tab {
    padding-top: 40px;
    margin-left: 7px;
  }
  .info-avatar {
    margin-top: 35px;
    padding: 0 18px 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 108px;
    border-radius: 13px;
    background-color: #fff;
    &-input {
      position: absolute;
      top: 13px;
      left: 13px;
      width: 250px;
      height: 81px;
      opacity: 0;
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .content-img {
        width: 81px;
        height: 81px;
        object-fit: cover;
        border-radius: 13px;
        flex-shrink: 0;
      }
      .content-text {
        margin-left: 18px;
        color: #333333;
        font-size: 20px;
      }
    }
    &-arrow {
      width: 48px;
      height: 48px;
    }
  }
  .common-input {
    margin-top: 22px;
  }
  .submit-button {
    margin-top: 79px;
  }
  .phone-number {
    margin-top: 22px;
    margin-bottom: 22px;
    font-size: 15px;
    text-align: center;
    color: #666666;
  }
}

@primary-color: #1DA57A;