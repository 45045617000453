.teacher-list-content {
  margin-top: 24px;
  background-color: var(--globalBg);
  position: relative;
  height: calc(100vh - 209px);
  height: calc(100vh - 209px - env(safe-area-inset-bottom));
  overflow-y: overlay;

  .search-wrapper {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    top: 0;
  }
  .rv-cell {
    align-items: flex-start;
    margin-bottom: 31px;
    padding: 0;
    &::after {
      content: "";
      border: none;
    }
    .rv-image {
      width: 66px;
      height: 66px;
      border-radius: 18px;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
    }
  }
  .rv-search__content {
    height: 3rem;
    background-color: #fff;
    .rv-cell__left-icon {
      color: #a0a4ab;
      font-size: 2rem;
    }
  }
  .rv-input__control {
  }
  .rv-cell--center .rv-cell__value {
    flex: initial;
  }
  .rv-cell__title {
    font-size: 17px;
    line-height: 1.25rem;
  }
  .teacher_intro {
    padding-left: 18px;
    // padding-right: 30px;
    color: var(--color3);
    overflow: hidden;
    font-size: 14px;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // max-height: 6em;
    line-height: 18px;
    // text-align: justify;
    text-justify: newspaper;
    // height: 36px;
  }
  .teacher-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: 18px;
    padding: 1px 0 1px 18px;
    margin-bottom: 9px;
    .teacher-title-name {
      font-size: 19px;
      color: var(--color1);
    }
    .teacher-title-icon-box {
      display: flex;
      align-items: center;
      .lottie-wrapper {
        width: 26px;
        height: 26px;
        &-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-icon {
      margin-left: 13px;
      display: inline-block;
      width: 26px;
      aspect-ratio: 1;
    }
    .teacher-list-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      margin-left: 33px;
      width: 40.7px;
      height: 17.4px;
      border: 1px solid #193bdb;
      box-sizing: border-box;
      border-radius: 5px;
      color: #1955ff;
      font-weight: 500;
      font-size: 8px;
      transform: scale(1.5);
      transform-origin: 100% 50%;
      white-space: nowrap;
    }
    .rv-button__text {
      letter-spacing: 1px;
      transform: translateY(1px);
      color: var(--primyColor1);
    }
  }
  .like-teacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    .rv-button--small {
      padding-left: 0.4rem !important;
      padding-right: 0.4rem !important;
      padding-top: 0.1rem !important;
      padding-bottom: 0.1rem !important;
      background-color: #167f71;
      font-size: 14px;
      color: #ffffff;
      width: 83px;
      height: 37px;
    }
    span {
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .bottom-text {
    width: 100%;
    height: 40px;
    line-height: 30px;
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
}

@primary-color: #1DA57A;