.study-today-container {
  .header-title {
    color: #333333;
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }
  .header-text {
    .text--1 {
      color: #333333;
      font-size: 14px;
      line-height: 20px;
    }
    .text--2 {
      margin-top: 5px;
      color: #999999;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .today-bottom {
    margin-top: 9px;
    padding: 13px;
    background: linear-gradient(270deg, #f6f8ff 0%, #c8d0ff 100%);
    border-radius: 18px;
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-pic {
          width: 66px;
          height: 88px;
          border-radius: 8px;
        }
        &-text {
          margin-left: 13px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          .common-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text--1 {
            color: #666666;
            font-size: 13px;
            line-height: 18px;
            .common-text();
          }
          .text--2 {
            margin-top: 4px;
            color: #333333;
            font-size: 16px;
            line-height: 23px;
            .common-text();
          }
          .text--3 {
            margin-top: 4px;
            color: #666666;
            line-height: 20px;
            .common-text();
          }
        }
      }
      .content-right {
        flex-shrink: 0;
        padding: 5px 8px;
        font-size: 15px;
        color: #fff;
        background: linear-gradient(90deg, #5872ff 0%, #2552ff 100%);
        border-radius: 8px;
      }
    }
    &-toast {
      margin-top: 13px;
      width: 100%;
      height: 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: linear-gradient(
        270deg,
        rgba(255, 248, 240, 0) 0%,
        #fff8f0 100%
      );
      border-radius: 12px;
      color: #ff8700;
      font-size: 12px;
      .toast-icon {
        margin: 0 7px;
        width: 13px;
      }
    }
  }
  .empty-block {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    border-radius: 18px;
    background: linear-gradient(270deg, #f6f8ff, #c8d0ff);
    box-sizing: border-box;
    &-title {
      color: #333;
      font-size: 16px;
    }
    &-button {
      margin-top: 15px;
      padding: 6px 14px;
      color: #fff;
      font-size: 13px;
      border-radius: 6px;
      background: linear-gradient(90deg, #5872ff, #2552ff);
    }
  }
  @media screen and (orientation: portrait) and (max-width: 767px) {
    .box--phone {
      .box--header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    .box--ipad {
      display: none;
    }
  }
  @media screen and (orientation: portrait) and (min-width: 768px) {
    .box--phone {
      display: none;
    }
    .box--ipad {
      .box-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          width: 46vw;
        }
      }
    }
  }
  @media screen and (orientation: landscape) {
    .box--phone {
      display: none;
    }
    .box--ipad {
      .box-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          width: 46vw;
        }
      }
    }
  }
}

@primary-color: #1DA57A;