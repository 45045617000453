.product-detail {
  width: 100%;
  height: 100%;
  background: linear-gradient(#dbdfff 0%, #f4f6ff 50%, #f4f6ff 100%);
  box-sizing: border-box;
  padding-top: 6rem;
  .rv-nav-bar__title {
    font-size: 21px;
  }
  .rv-nav-bar {
    padding-top: 2rem;
  }
  .card {
    border-radius: 0.6rem;
    background-color: #fff;
    box-sizing: border-box;
    padding: 1rem;
  }
  .product-detail-context {
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    .top1 {
      > div:not(:last-child) {
        margin-bottom: 0.8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .top1_status {
        color: var(--primyColor6);
        font-weight: 600;
        font-size: 18px;
      }
      .top1_default {
        color: var(--color2);
        font-size: 13px;
      }
      .top1_default1 {
        color: var(--color2);
        display: flex;
        align-items: center;
        font-size: 13px;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          width: 0;
        }
        img {
          width: 1rem;
          aspect-ratio: 1;
        }
      }
      .top1_bottom {
        text-align: end;
        color: var(--color2);
        span {
          color: var(--primyColor8);
          font-weight: 600;
          font-size: 1.2rem;
        }
      }
    }
    .content {
      margin-top: 1rem;
      .content_title {
        margin-bottom: 1rem;
        color: var(--color2);
        font-weight: 600;
      }
      .top {
        display: flex;
        align-items: flex-start;
        img {
          width: 5rem;
          aspect-ratio: 1;
          border-radius: 1rem;
        }
        .top_right {
          flex: 1;
          width: 0;
          margin-left: 0.6rem;
          .default() {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--color4);
            font-size: 0.8rem;
            margin-top: 0.4rem;
          }
          .top_right_title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--color2);
            font-weight: 600;
          }
          .top_right_orderCode {
            .default();
          }
        }
      }
      .msg {
        margin-top: 1rem;
        color: var(--color2);
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        text-align: left;
      }
      .btn {
        width: 100%;
        border: 1px solid var(--primyColor1);
        color: var(--primyColor1);
        font-weight: 600;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-radius: 17px;
        margin-top: 10px;
      }
    }
  }
}

@primary-color: #1DA57A;