.recommend-container {
  position: relative;
  img {
    user-select: none;
  }
  min-height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  box-sizing: border-box;
  .common-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px auto 0;
    width: 393px;
    height: 149px;
    border-radius: 13px;
    background-color: #fff;
    box-sizing: border-box;
  }
  .common-confirm {
    margin-top: 33px;
    width: 154px;
    height: 49px;
    background-image: url("/images/mine/recommend-officer/button_back.png");
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 49px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    // &:active {
    //   background-image: url("/images/mine/recommend-officer/button_back--active.png");
    // }
  }
  .box-none {
    .common-box();
    flex-direction: column;
    &-text {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    &-add {
      margin-top: 23px;
      width: 83px;
      height: 31px;
      line-height: 31px;
      text-align: center;
      border-radius: 15px;
      border: 1px solid #1955ff;
      color: #1955ff;
      background-color: #fff;
      font-size: 14px;
      box-sizing: border-box;
    }
  }
  .box-has {
    .common-box();
    padding: 0 18px;
    &-left {
      margin-right: 16px;
      width: 81px;
      height: 81px;
      border-radius: 13px;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-text {
        padding-left: 16px;
        width: 161px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-left: 1px solid #dddddd;
      }
      .right-change {
        width: 83px;
        height: 31px;
        border: 1px solid #1955ff;
        border-radius: 15px;
        box-sizing: border-box;
        text-align: center;
        line-height: 31px;
        color: #1955ff;
        font-size: 14px;
        background-color: #fff;
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(5, 1, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .code-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 336px;
      height: 394px;
      background-image: url("/images/mine/recommend-officer/code_back.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-title {
        margin-top: 166px;
        font-size: 18px;
        font-weight: bold;
        color: #006b98;
      }
      &-input {
        margin-top: 30px;
        width: 256px;
        height: 66px;
        background: #f3fbff;
        border-radius: 11px;
        border: 2px solid #38c4ff;
        box-sizing: border-box;
        text-align: center;
        line-height: 66px;
        color: #009fe2;
        font-size: 19px;
        font-weight: bold;
      }
      &-confirm {
        .common-confirm();
      }
    }
    .change-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 336px;
      height: 409px;
      background-image: url("/images/mine/recommend-officer/change_back.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 182px;
        width: 252px;
        height: 106px;
        border-radius: 11px;
        background-color: rgba(40, 168, 222, 0.1);
        font-size: 19px;
        font-weight: bold;
        .title-wrapper {
          text-align: center;
          .color-1 {
            color: #ff8b23;
          }
          .color-2 {
            color: #006b98;
          }
        }
      }
      &-confirm {
        .common-confirm();
      }
    }
    .prompt-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 336px;
      height: 382px;
      background-image: url("/images/mine/recommend-officer/prompt_back.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &-title {
        padding-left: 120px;
        width: 100%;
        margin-top: 80px;
        font-size: 23px;
        font-weight: 500;
        color: rgba(0, 107, 152, 1);
        text-align: left;
      }
      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        width: 252px;
        height: 106px;
        border-radius: 11px;
        background-color: rgba(40, 168, 222, 0.1);
        color: #006b98;
        font-size: 19px;
        font-weight: bold;
        text-align: center;
      }
      &-confirm {
        .common-confirm();
      }
    }
    &-close {
      margin-top: 33px;
      width: 24px;
      height: 24px;
    }
  }
}

@primary-color: #1DA57A;