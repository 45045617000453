.my-order-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  height: 100vh;
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
    z-index: 1;
  }
  .order-list {
    padding: 0 18px;
    flex: 1;
    width: 100%;
    overflow-y: overlay;
    z-index: 2;
    box-sizing: border-box;
    &-item {
      margin-bottom: 20px;
      padding: 16px 23px;
      background-color: #fff;
      border-radius: 5px;
      .item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }
        &-right {
          flex-shrink: 0;
          color: #d43030;
          font-size: 20px;
        }
      }
      .item-body {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-pic {
          margin-right: 25px;
          max-width: 30%;
          height: 71px;
          object-fit: contain;
          border-radius: 5px;
        }
        &-text {
          width: 70%;
          height: 71px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          .text-item {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #999999;
            font-size: 13px;
          }
        }
      }
      .item-footer-amount {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .amount-money {
          color: #ed1919;
          font-size: 20px;
        }
        .amount-status {
          color: #ff8700;
          font-size: 20px;
        }
        .amount-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 83px;
          height: 30px;
          color: #1955ff;
          font-size: 14px;
          border-radius: 15px;
          border: 1px solid #1955ff;
        }
      }
      .item-footer-cell {
        margin-top: 16px;
        .more-button {
          display: flex;
          align-items: center;
          margin-top: 4px;
          color: #979797;
          font-size: 14px;
          &-text {
            margin-right: 9px;
          }
          &-icon {
            margin-top: 4px;
          }
        }
        .cell {
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-order {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            color: #333333;
            font-size: 13px;
            white-space: nowrap;
            &-number {
              margin-left: 8px;
              color: #979797;
            }
          }
          &-title {
            color: #333333;
            font-size: 13px;
          }
          &-text {
            color: #979797;
            font-size: 13px;
          }
          &-copy {
            color: #3d3d3d;
            font-size: 13px;
          }
        }
      }
    }
    &-bottom {
      width: 100%;
      height: 40px;
      line-height: 30px;
      color: #999999;
      font-size: 13px;
      text-align: center;
    }
  }
}

@primary-color: #1DA57A;