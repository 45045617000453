.lesson-state {
  padding-top: 2rem;
  .rv-nav-bar {
    padding-top: 2rem;
    .rv-nav-bar__text {
      font-size: 21px;
      font-family: JostSemiBold;
      color: #202244;
    }
  }
  .lesson-state-content {
    background-color: #fff;
    padding: 1rem;
  }
}

@primary-color: #1DA57A;