.modify-password-container {
  img {
    user-select: none;
  }
  padding: 0 18px;
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
  }
  .top-banner {
    margin: 26px 0 40px 7px;
    width: 157px;
  }
  .login-tab {
    margin-left: 7px;
  }
  .login-input {
    margin-top: 22px;
  }
  .login-submit {
    margin-top: 66px;
    margin-bottom: 66px;
  }
}

@primary-color: #1DA57A;