.course-container {
  overflow-y: overlay;
  height: 100vh;
  padding: 0 22px;
  .course-header {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    &-left {
      flex-shrink: 0;
      width: 79px;
      border-radius: 8px;
    }
    &-center {
      margin-left: 29px;
      flex: 1;
      .text-top {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        color: #ff8700;
        font-size: 20px;
        line-height: 27px;
      }
      .text-center {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        margin-top: 10px;
        color: #202244;
        font-size: 16px;
        line-height: 23px;
      }
      .text-bottom {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        margin-top: 13px;
        color: #666666;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &-right {
      width: 35px;
    }
  }
  .course-center {
    margin-top: 14px;
    &-title {
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
    }
    &-text {
      margin-top: 10px;
      color: #666666;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .course-bottom {
    margin-top: 14px;
    &-unit {
      width: 100px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1955ff;
      font-size: 13px;
      background-color: #f4f6ff;
      border-radius: 5px;
      .unit-title {
        margin-right: 4px;
      }
    }
    .course-list {
      margin-top: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media screen and (orientation: portrait) and (max-width: 767px) {
        &-item {
          width: 48%;
          &:nth-child(odd) {
            margin-right: 4%;
          }
        }
      }
      @media screen and (orientation: portrait) and (min-width: 768px) {
        &-item {
          &:not(:nth-child(4n-3)) {
            margin-left: 4%;
          }
          width: 22%;
        }
      }
      @media screen and (orientation: landscape) {
        &-item {
          &:not(:nth-child(4n-3)) {
            margin-left: 4%;
          }
          width: 22%;
        }
      }
      &-item {
        margin-bottom: 12px;
        .item-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .left-icon {
              width: 25px;
            }
            .left-text {
              margin-left: 3px;
              font-size: 10px;
              line-height: 14px;
              &-status--1 {
                color: #383838;
              }
              &-status--2 {
                color: #d43030;
              }
              &-status--3 {
                color: #2a82e4;
              }
            }
          }
          &-right {
            color: #2a82e4;
            font-size: 11px;
            line-height: 16px;
          }
        }
        .item-cover {
          position: relative;
          margin-top: 3px;
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
          &-pic {
            position: relative;
            width: 100%;
          }
          &-recently {
            position: absolute;
            top: 0;
            right: 0;
            width: 59px;
            height: 24px;
            color: #383838;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
            line-height: 24px;
            background-color: #ffeb3b;
            border-bottom-left-radius: 12px;
          }
          &-grey {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(80, 80, 80, 0.5);
          }
        }
        .item-title {
          margin-top: 3px;
          color: #383838;
          font-size: 11px;
          line-height: 15px;
          text-align: center;
          &--finish {
            color: #999999;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;