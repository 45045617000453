.mailing-address-container {
  img {
    user-select: none;
  }
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  .address-list {
    padding: 0px 17px 114px 17px;
    &-item {
      margin-top: 13px;
      padding: 13px;
      height: 136px;
      box-sizing: border-box;
      border-radius: 13px;
      border: 1px solid #979797;
      .item-top {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 15px;
        &-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          max-width: 250px;
          color: #333333;
          font-weight: bold;
        }
        &-phone {
          margin-left: 9px;
          color: #666666;
        }
      }
      .item-center {
        height: 44px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        margin-top: 9px;
        color: #333333;
        font-size: 15px;
      }
      .item-bottom {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-update {
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 15px;
          line-height: 22px;
          &-icon {
            margin-right: 6px;
            width: 22px;
            height: 22px;
          }
        }
        &-delete {
          display: flex;
          align-items: center;
          margin-left: 147px;
          color: #ed1919;
          font-size: 15px;
          line-height: 22px;
          &-icon {
            margin-right: 6px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
  .form-list {
    padding: 13px 18px;
    &-item {
      margin-bottom: 18px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .item-title {
        margin-top: 15px;
        color: #333333;
        font-size: 15px;
        font-weight: bold;
      }
      .item-input {
        padding-left: 13px;
        width: 305px;
        height: 53px;
        border-radius: 13px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        color: #333333;
        font-size: 15px;
        border: none;
        &::placeholder {
          color: #999999;
        }
      }
      .item-textarea {
        padding-top: 15px;
        height: 95px;
      }
      .item-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 13px;
        width: 305px;
        height: 53px;
        font-size: 15px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        border-radius: 13px;
        &-placeholder {
          color: #999999;
        }
        &-text {
          color: #333333;
        }
        &-icon {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
  .operate-button {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 110px;
    padding: 9px 18px;
    box-sizing: border-box;
    background-color: #fff;
  }
}

@primary-color: #1DA57A;