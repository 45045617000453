.learn-teacher {
  background-color: var(--globalBg);
  padding: 0px 20px 80px 20px;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  .block-sticky {
    height: 61px;
    position: sticky;
    top: 0;
    background-color: var(--globalBg);
    z-index: 998;
  }
  .learn-teacher-content {
    position: relative;
    .rv-empty__description {
      text-align: center;
    }
  }
  .fang_da_jing {
    position: absolute;
    width: 26px;
    aspect-ratio: 1;
    right: 26px;
    top: 70px;
    z-index: 1000;
  }
  .sk-container {
    margin-top: 30px;
  }
}

@primary-color: #1DA57A;