.register-container {
  img {
    user-select: none;
  }
  padding: 0 18px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 230px;
  }
  .top-banner {
    margin: 26px 0 40px 7px;
    width: 157px;
  }
  .login-tab {
    margin-left: 7px;
  }
  .login-input {
    margin-top: 22px;
  }
  .operate-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 79px;
    font-size: 14px;
    color: #545454;
    &-item {
      display: flex;
      align-items: center;
      .item-text {
        margin: 0 9px;
      }
      .item-icon {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background-color: #d4dfff;
        color: #1955ff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        &--checked {
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 8px;
            border: solid #1955ff;
            border-width: 0 3px 3px 0;
            top: 2px;
            left: 7px;
            transform: rotate(35deg);
          }
        }
      }
    }
  }
  .check-way {
    margin-top: 26px;
    font-size: 15px;
    text-align: center;
    &-left {
      color: #333333;
    }
    &-right {
      color: #1955ff;
    }
  }
  .bottom-rule {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    &-text {
      margin-left: 9px;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      color: #545454;
      &--link {
        margin: 0 2px;
        color: #1955ff;
      }
    }
    &-icon {
      position: relative;
      width: 22px;
      height: 22px;
      border: 2px solid #dddddd;
      border-radius: 4px;
      background-color: #fff;
      flex-shrink: 0;
      &--checked {
        background-color: #d4dfff;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 10px;
          border: solid #1955ff;
          border-width: 0 4px 4px 0;
          top: 2px;
          left: 7px;
          transform: rotate(35deg);
        }
      }
    }
  }
}

@primary-color: #1DA57A;