.main-container {
  height: 100%;
  padding: 0 18px;
  background: linear-gradient(180deg, #e9edff 0%, #fafbff 100%);

  &-banner{
    position: absolute;
    top: 0;
    right: 0;
    height: 130px;
    width: 188px;
  }

  .classHour {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 44px;
    width: 100%;
    height: 92px;
    background-image: url("/images/mine/ic_back.png");
    background-repeat: no-repeat;
    background-size: 100% auto;

    &-item {
      flex: 1;
      text-align: center;
      flex-shrink: 0;
      .item-hour {
        font-weight: bold;
        &-text {
          // color: #fff;
          color: #ffd581;
          font-size: 20px;
        }
        &-unit {
          color: #ffd581;
          font-size: 15px;
        }
        &-integral {
          color: #ffd581;
          font-size: 20px;
        }
        .hour {
          margin-left: 0.1rem;
          color: #ffd581;
        }
      }
      .item-desc {
        margin-top: 4px;
        color: #bababa;
        font-size: 13px;
      }
    }
  }

  .class-record{
    position: relative;
    top: -12px;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 18px 18px;
    height: 70vh;
    overflow-y: auto;

    &-list{
      margin: 15px 0 15px 0;
      display: flex;
      flex-direction: column;
      &-item1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-text1{
          color:#333333;
          font-size: 16px;
          //text-overflow: ellipsis;
          //-webkit-line-clamp: 1;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //overflow: hidden;
        }
        &-text2{
          color:#FF8700;
          font-size: 14px;
        }

      }
      &-item2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color:#999999;
        font-size: 14px;
      }
    }
  }

}

@primary-color: #1DA57A;