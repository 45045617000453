.main-container{
  .top-banner{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 30%;
    width: 100%;
    background: linear-gradient( 360deg, #F4F6FF 0%, #CCD2FF 100%);
    z-index: 2;
  }
  .top-container{
    position: fixed;
    top: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 4;
    //.top-left{
    //  padding-top: 15px;
    //  width: 130px;
    //  height: 130px;
    //}
    .top-right{
      padding-bottom: 50px;
      margin-right: 20px;
      .top-right-text1{
        color: #1955FF;
        font-size: 40px;
        font-weight: bold;
      }
      .top-right-text2{
        color: #333333;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .search-QA{
    position: fixed;
    top: 29%;
    background-color:#fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    z-index: 3;
    .qa-text{
      color: #3D3D3D;
      font-weight: bold;
      font-size: 20px;
      margin-left: 20px;
    }
    .qa-input{
      font-size: 16px;
      background-color: #f2f2f2;
      width: 200px;
      height: 30px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      margin: 5px 10px 5px 0;
      padding:5px;

      .qa-icon{
        width: 30px;
        height: 30px;
      }

      input{
        width: 100%;
        border:none;
        font-size: 16px;
        background-color: transparent;
        padding-right: 10px;
      }
    }
  }
  .rv-list{
    position: absolute;
    width: 100%;
    height: 55%;
    top: 35%;
    z-index: 1;
    overflow-y: auto;

    .tab-list{
      height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 20px 10px 10px 0;
      border-bottom: 0.5px #c4c4c4 solid;

      .tab-item{
        color: #3D3D3D;
        font-size: 16px;
        width: 90%;
        text-overflow: ellipsis;
        padding: 0 0 0 15px;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .tab-item-icon{
        width: 25px;
        height: 25px;
        margin-right: 15px;
      }
    }
  }
  .tab-container{
    z-index: 5;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient( 180deg, #FFFFFF 0%, #D3E3FF 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .tab-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px;
      .tab-text{
        color:#3D3D3D;
        font-size: 14px;
      }
      .img1{
        width: 30px;
        height: 35px;
      }
      .img2{
        width: 30px;
        height: 30px;
      }
    }
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .top-container{
      top: 5%;
    }
    .top-banner{
      top: -70px;
      height: 50%;
    }
    .rv-list{
      top:38%;
      height: 45%;
    }
  }
  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .top-banner{
      height: 32%;
    }
  }
}

@primary-color: #1DA57A;