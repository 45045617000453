.banners-content {
  @media screen and (orientation: portrait) and (max-width: 767px) {
    .banner-box--phone {
      width: 100%;
      .rv-image__img {
        border-radius: 18px;
      }
      .swiper {
        width: 100%;
        height: 111px;
      }
      .swiper-pagination {
        width: 30% !important;
        padding-left: 13px;
        text-align: left;
        .swiper-pagination-bullet {
          margin: 0 2px !important;
          width: 7px;
          height: 3px;
          background-color: #ffffff;
          opacity: 0.5;
          border-radius: 2px;
        }
        .swiper-pagination-bullet-active {
          width: 13px;
          height: 3px;
          background-color: #ffffff;
          border-radius: 2px;
          opacity: 1;
        }
      }
    }
    .banner-box--ipad {
      display: none;
    }
  }
  @media screen and (orientation: portrait) and (min-width: 768px) {
    .banner-box--phone {
      display: none;
    }
    .banner-box--ipad {
      overflow-x: overlay;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      .banner-item {
        margin-right: 10px;
        display: block;
        height: 120px;
        border-radius: 18px;
      }
      .rv-image__img {
        width: auto;
        height: 100%;
        border-radius: 18px;
      }
      .rv-image {
        width: auto;
      }
      .rv-image__error {
        width: 400px;
      }
    }
  }
  @media screen and (orientation: landscape) {
    .banner-box--phone {
      display: none;
    }
    .banner-box--ipad {
      overflow-x: overlay;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      .banner-item {
        margin-right: 10px;
        display: block;
        height: 120px;
        border-radius: 18px;
      }
      .rv-image__img {
        width: auto;
        height: 100%;
        border-radius: 18px;
      }
      .rv-image {
        width: auto;
      }
      .rv-image__error {
        width: 400px;
      }
    }
  }
}

@primary-color: #1DA57A;