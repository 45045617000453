.dice-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.8);
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("../../images/game/dice/bg-top.png");
    background-size: 100% 100%;
  }
  .bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("../../images/game/dice/bg-bottom.png");
    background-size: 100% 100%;
  }
  .top-card {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    user-select: none;
  }
  .dice-img {
    position: absolute;
    left: calc(50% - 80px);
    top: 40%;
    width: 160px;
    height: 177px;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 1;
    background-image: url("../../images/game/dice/dice-1.png");
    &--turn {
      animation: turn linear 3s forwards;
    }
    &--fade-out {
      // opacity: 0;
      // transition: all 2s;
      animation: hide_animation 1s linear 1s forwards;
    }
  }
  .ubao-img {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0) scale(0.5);
    height: 194px;
    user-select: none;
    opacity: 0;
    &--fade-in {
      opacity: 1;
      transform: translate(-50%, 0) scale(1);
      transition: all 1s 2s;
    }
  }
  .start-button {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 93.5px);
    width: 187px;
    height: 71px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/game/dice/button-start.png");
    animation: flicker 0.3s linear infinite alternate;
    &--disabled {
      background-image: url("../../images/game/dice/button-start-disabled.png");
      animation: none;
    }
  }
  @keyframes flicker {
    from {
      transform: scale(0.96);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes turn {
    0% {
      background-image: url("../../images/game/dice/dice-1.png");
      transform: translateX(-10px) rotate(0deg);
    }
    10% {
      transform: translateX(10px) rotate(360deg);
    }
    20% {
      background-image: url("../../images/game/dice/dice-5.png");
      transform: translateX(-10px) rotate(720deg);
    }
    30% {
      transform: translateX(10px) rotate(1080deg);
    }
    40% {
      background-image: url("../../images/game/dice/dice-4.png");
      transform: translateX(-10px) rotate(1440deg);
    }
    50% {
      transform: translateX(10px) rotate(1800deg);
    }
    60% {
      background-image: url("../../images/game/dice/dice-2.png");
      transform: translateX(-10px) rotate(2160deg);
    }
    70% {
      transform: translateX(10px) rotate(2520deg);
    }
    80% {
      background-image: url("../../images/game/dice/dice-5.png");
      transform: translateX(-10px) rotate(2880deg);
    }
    90% {
      transform: translateX(10px) rotate(3240deg);
    }
    100% {
      background-image: url("../../images/game/dice/dice-2.png");
      transform: translateX(0px) rotate(3600deg);
    }
  }
  @keyframes hide_animation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .bg-top {
      height: 50%;
      background-image: url("../../images/game/dice/bg-top-pad.png");
    }
    .bg-bottom {
      height: 100%;
      background-image: url("../../images/game/dice/bg-bottom-pad.png");
    }
    .top-card {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 100px;
      user-select: none;
    }
    .dice-img {
      position: absolute;
      left: calc(50% - 50px);
      top: 30%;
      width: 100px;
      height: 120px;
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 1;
      background-image: url("../../images/game/dice/dice-1.png");
      &--turn {
        animation: turn linear 3s forwards;
      }
      &--fade-out {
        // opacity: 0;
        // transition: all 2s;
        animation: hide_animation 1s linear 1s forwards;
      }
    }
    .ubao-img {
      position: absolute;
      top:25%;
      left: 50%;
      transform: translate(-50%, 0) scale(0.5);
      height: 134px;
      user-select: none;
      opacity: 0;
      &--fade-in {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
        transition: all 1s 2s;
      }
    }
    .start-button {
      bottom: 15px;
    }
  }
  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .bg-top {
      height: 50%;
    }
    .bg-bottom {
      height: 50%;
    }
    .top-card {
      position: absolute;
      top: 5%;
      left: 0;
      width: 100%;
      user-select: none;
    }
    .dice-img {
      top: 40%;
    }
    .ubao-img {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, 0) scale(0.5);
      height: 194px;
      user-select: none;
      opacity: 0;
      &--fade-in {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
        transition: all 1s 2s;
      }
    }
    .start-button {
      position: absolute;
      bottom: 30px;
      left: calc(50% - 93.5px);
      width: 187px;
      height: 71px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../images/game/dice/button-start.png");
      animation: flicker 0.3s linear infinite alternate;
      &--disabled {
        background-image: url("../../images/game/dice/button-start-disabled.png");
        animation: none;
      }
    }
  }
}

@primary-color: #1DA57A;