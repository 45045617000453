.recommended {
  position: relative;
  .rv-skeleton--round .rv-skeleton__row,
  .rv-skeleton--round {
    border-radius: 0;
  }
  .swiper-slide .swiper-slide-active {
    margin-right: 6rem;
  }
  .rv-image {
    width: 100%;
    aspect-ratio: 1.94;
    overflow: hidden;
  }
  .series-swiper-slide {
    width: 100%;
    background-color: rgba(#fff);
    border-radius: 1rem;
    overflow: hidden;
    .rv-image img {
      display: inline;
      height: auto;
    }
  }
  .series-slide-text {
    box-sizing: border-box;
    padding: 0 9px 9px;
  }
  .tj {
    color: var(--color3);
    font-size: 13px;
    margin-top: 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .xl {
    color: var(--color2);
    font-size: 14px;
    margin-top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
  }
  .series-swiper-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color4);
    font-size: 11px;
    margin-top: 0.34rem;
    .favorite {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .xin {
        margin-top: 1px;
        margin-left: 2px;
        width: 13px;
        height: 13px;
        aspect-ratio: 1;
        position: relative;
      }
    }
    .study-count {
      flex-shrink: 0;
    }
  }
  .recommend-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-x: overlay;
    overflow-y: hidden;
    &-item {
      flex-shrink: 0;
      background-color: rgba(#fff);
      border-radius: 8px;
      overflow: hidden;
    }
  }
  @media screen and (orientation: portrait) and (max-width: 767px) {
    .recommend-box {
      &-item {
        margin-right: 10px;
        width: calc((100% - 20px) / 3);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (orientation: portrait) and (min-width: 768px) {
    .recommend-box {
      &-item {
        margin-right: 10px;
        width: calc((100% - 20px) / 3);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (orientation: landscape) {
    .recommend-box {
      &-item {
        // height: 100px;
        display: flex;
        margin-right: 10px;
        width: calc((100% - 20px) / 3);
        .series-slide-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          padding: 0;
          .tj {
            margin-top: 0px;
            font-size: 10px;
            margin-left: 6px;
          }
          .xl {
            margin-top: 0px;
            font-size: 11px;
            margin-left: 6px;
            height: auto;
          }
          .series-swiper-bottom {
            margin-top: 0px;
            font-size: 8px;
            margin-left: 6px;
            width: calc(100% - 18px);
          }
        }
        .rv-image {
          width: 50%;
          overflow: hidden;
          img {
            height: auto;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .series-slide-text {
          width: 50%;
        }
      }
    }
  }
}
.recommended-pop-box {
  position: relative;
  width: 339px;
  height: 284px;
  background-size: 100% auto;
  background-image: url("/images/image/recommended-pop.png");
  background-repeat: no-repeat;
  &-cancel {
    position: absolute;
    top: 109px;
    right: 26px;
    width: 24px;
  }
  &-text {
    margin: 180px auto 0;
    width: 75%;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
  }
}

@primary-color: #1DA57A;