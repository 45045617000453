.turntable-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.8);
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background-image: url("../../images/game/turntable/background-top.png");
    background-size: 100% 100%;
  }
  .bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background-image: url("../../images/game/turntable/background-bottom.png");
    background-size: 100% 100%;
  }
  .turntable {
    position: relative;
    margin:40% auto 0;
    width: 358px;
    height: 358px;
    z-index: 200;
    .round {
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../images/game/turntable/turn-normal.png");
      transform: rotate(0deg);
    }
    .cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &-select {
        position: absolute;
        top: 14px;
        left: 65px;
        width: 180px;
        transform: rotate(10deg);
        transform-origin: 50% 100%;
        user-select: none;
      }
    }
    .score {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      &--2 {
        position: absolute;
        left: 53px;
        top: 38px;
        width: 127px;
        user-select: none;
      }
      &--4 {
        position: absolute;
        right: 73px;
        top: 62px;
        width: 91px;
        user-select: none;
      }
      &--6 {
        position: absolute;
        top: 153px;
        right: 28px;
        width: 108px;
        user-select: none;
      }
      &--8 {
        position: absolute;
        bottom: 25px;
        left: 112px;
        width: 111px;
        user-select: none;
      }
      &--10 {
        position: absolute;
        top: 158px;
        left: 35px;
        width: 90px;
        user-select: none;
      }
    }
    .pointer {
      position: absolute;
      top: calc(50% - 58.5px);
      left: calc(50% - 51.5px);
      width: 103px;
      height: 117px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../images/game/turntable/pointer-normal.png");
      &--flicker {
        animation: pointer_animation 0.1s linear infinite alternate;
      }
    }
    &--hide {
      animation: turn_hide 2s linear forwards;
    }
  }
  .settlement {
    position: relative;
    margin: 40% auto 0;
    width: 358px;
    height: 358px;
    z-index: 200;
    opacity: 0;
    animation: turn_show 1s linear forwards;
    .end-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../images/game/turntable/turn-end.png");
      &-score {
        height: 194px;
        user-select: none;
        transform: scale(0.2);
        transform-origin: center;
        animation: end_scale 1s linear forwards;
      }
    }
  }
  .chassis {
    position: relative;
    top: -8px;
    display: block;
    margin: 0 auto;
    width: 229px;
    user-select: none;
    z-index: 100;
  }
  .animation-class--2 {
    animation: rotate_2 3s ease-out forwards;
  }
  .animation-class--4 {
    animation: rotate_4 3s ease-out forwards;
  }
  .animation-class--6 {
    animation: rotate_6 3s ease-out forwards;
  }
  .animation-class--8 {
    animation: rotate_8 3s ease-out forwards;
  }
  .animation-class--10 {
    animation: rotate_10 3s ease-out forwards;
  }
  @keyframes pointer_animation {
    0% {
      background-image: url("../../images/game/turntable/pointer-normal.png");
      transform: scale(1);
    }
    100% {
      background-image: url("../../images/game/turntable/pointer-select.png");
      transform: scale(0.99);
    }
  }
  @keyframes rotate_2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1113deg);
    }
  }
  @keyframes rotate_10 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1185deg);
    }
  }
  @keyframes rotate_8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1257deg);
    }
  }
  @keyframes rotate_6 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1329deg);
    }
  }
  @keyframes rotate_4 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1401deg);
    }
  }
  @keyframes end_scale {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes turn_hide {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes turn_show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .bg-top{
      height: 40%;
      background-image: url("../../images/game/turntable/background-top-pad.png");
    }
    .bg-bottom{
      height: 55%;
      background-image: url("../../images/game/turntable/background-bottom-pad.png");
    }
    .turntable {
      position: relative;
      margin:14% auto 0;
      width: 358px;
      height: 358px;
      z-index: 200;
      .round {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/turn-normal.png");
        transform: rotate(0deg);
      }
      .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &-select {
          position: absolute;
          top: 14px;
          left: 65px;
          width: 180px;
          transform: rotate(10deg);
          transform-origin: 50% 100%;
          user-select: none;
        }
      }
      .score {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: rotate(0deg);
        &--2 {
          position: absolute;
          left: 53px;
          top: 38px;
          width: 127px;
          user-select: none;
        }
        &--4 {
          position: absolute;
          right: 73px;
          top: 62px;
          width: 91px;
          user-select: none;
        }
        &--6 {
          position: absolute;
          top: 153px;
          right: 28px;
          width: 108px;
          user-select: none;
        }
        &--8 {
          position: absolute;
          bottom: 25px;
          left: 112px;
          width: 111px;
          user-select: none;
        }
        &--10 {
          position: absolute;
          top: 158px;
          left: 35px;
          width: 90px;
          user-select: none;
        }
      }
      .pointer {
        position: absolute;
        top: calc(50% - 58.5px);
        left: calc(50% - 51.5px);
        width: 103px;
        height: 117px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/pointer-normal.png");
        &--flicker {
          animation: pointer_animation 0.1s linear infinite alternate;
        }
      }
      &--hide {
        animation: turn_hide 2s linear forwards;
      }
    }
    .settlement {
      position: relative;
      margin: 15% auto 0;
      width: 358px;
      height: 358px;
      z-index: 200;
      opacity: 0;
      animation: turn_show 1s linear forwards;
      .end-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/turn-end.png");
        &-score {
          height: 194px;
          user-select: none;
          transform: scale(0.2);
          transform-origin: center;
          animation: end_scale 1s linear forwards;
        }
      }
    }
  }

  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .bg-top{
      height: 45%;
    }
    .bg-bottom{
      height: 45%;
    }
    .turntable {
      position: relative;
      margin:30% auto 0;
      width: 600px;
      height: 600px;
      z-index: 200;
      .round {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/turn-normal.png");
        transform: rotate(0deg);
      }
      .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &-select {
          position: absolute;
          top: 18px;
          left: 110px;
          width: 300px;
          transform: rotate(10deg);
          transform-origin: 50% 100%;
          user-select: none;
        }
      }
      .score {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: rotate(0deg);
        &--2 {
          position: absolute;
          left: 90px;
          top: 60px;
          width: 35%;
          user-select: none;
        }
        &--4 {
          position: absolute;
          right: 110px;
          top: 90px;
          width: 30%;
          user-select: none;
        }
        &--6 {
          position: absolute;
          top: 250px;
          right: 30px;
          width: 32%;
          user-select: none;
        }
        &--8 {
          position: absolute;
          bottom: 40px;
          left: 182px;
          width: 35%;
          user-select: none;
        }
        &--10 {
          position: absolute;
          top: 270px;
          left: 80px;
          width: 25%;
          user-select: none;
        }
      }
      .pointer {
        position: absolute;
        top: calc(50% - 58.5px);
        left: calc(50% - 51.5px);
        width: 103px;
        height: 117px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/pointer-normal.png");
        &--flicker {
          animation: pointer_animation 0.1s linear infinite alternate;
        }
      }
      &--hide {
        animation: turn_hide 2s linear forwards;
      }
    }
    .settlement {
      position: relative;
      margin: 30% auto 0;
      width: 600px;
      height: 600px;
      z-index: 200;
      opacity: 0;
      animation: turn_show 1s linear forwards;
      .end-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../../images/game/turntable/turn-end.png");
        &-score {
          height: 50%;
          user-select: none;
          transform: scale(0.2);
          transform-origin: center;
          animation: end_scale 1s linear forwards;
        }
      }
    }
  }
}


@primary-color: #1DA57A;