.my-textbook-container {
  position: relative;
  overflow-y: overlay;
  padding-top: 7px;
  padding-left: 22px;
  padding-right: 35px;
  height: calc(100vh - 105px);
  height: calc(100vh - 105px - env(safe-area-inset-bottom));
  overflow-y: overlay;
  box-sizing: border-box;
  .textbook-item {
    padding: 13px 0 11px;
    &-time {
      margin-left: 4px;
      color: #808080;
      font-size: 13px;
      line-height: 19px;
    }
    &-content {
      margin-top: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-pic {
          flex-shrink: 0;
          width: 66px;
          border-radius: 8px;
        }
        &-desc {
          flex: 1;
          margin-left: 16px;
          min-height: 86px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .common-desc {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .desc-series {
            font-size: 13px;
            color: #ff8d1a;
            line-height: 19px;
            .common-desc();
          }
          .desc-books {
            margin-top: 7px;
            font-size: 16px;
            color: #202244;
            line-height: 23px;
            .common-desc();
          }
          .desc-title {
            margin-top: 5px;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            .common-desc();
          }
        }
      }

      .content-right {
        flex-shrink: 0;
        &-pro {
          width: 40px;
        }
      }
    }
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .bottom-text {
    margin-bottom: 80px;
    width: 100%;
    height: 40px;
    line-height: 30px;
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
  .empty-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .center-pic {
      width: 150px;
    }
    .center-text {
      margin-bottom: 80px;
      margin-top: 20px;
      font-size: 14px;
      text-align: center;
      line-height: 25px;
      color: #999999;
    }
  }
}

@primary-color: #1DA57A;