.tabs-wrapper {
  display: flex;
  &-item {
    margin-right: 27px;
    position: relative;
    color: var(--color3);
    font-size: 20px;
    font-weight: bold;
    &--selected {
      color: #333333;
      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: calc(50% - 27px);
        width: 54px;
        height: 13px;
        border-radius: 13px;
        background: linear-gradient(90deg, #adbaff 0%, #809aff 100%);
        z-index: 50;
      }
    }
    &-text {
      position: relative;
      z-index: 100;
    }
  }
}

@primary-color: #1DA57A;