.common-bar {
  position: relative;
  z-index: 100;
  padding-top: 52px;
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);
  &--no-safe {
    padding-top: 0;
  }
  img {
    user-select: none;
  }
  .placeholder-block {
    height: 48px;
  }
  .bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 48px;
    padding-top: 52px;
    // padding-top: constant(safe-area-inset-top);
    // padding-top: env(safe-area-inset-top);
    &-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 48px;
      .content-icon {
        position: absolute;
        top: 0;
        left: 13px;
        width: 48px;
        height: 48px;
      }
      .content-title {
        max-width: 70%;
        font-size: 19px;
        font-weight: 500;
        color: #202244;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@primary-color: #1DA57A;