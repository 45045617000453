@media only screen and (max-width: 500px) {
  .system-setting-container {
    img {
      user-select: none;
    }
    background-color: #fff;
    position: relative;
    height: 100vh;
    overflow-y: overlay;
    .option-list {
      margin-top: 9px;
      padding: 9px 17px 9px 30px;
      &-item {
        &:not(:last-child) {
          margin-bottom: 18px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        .item-left {
          display: flex;
          align-items: center;
          &-icon {
            width: 35px;
            height: 35px;
          }
          &-title {
            margin-left: 9px;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .item-right {
          width: 48px;
          height: 48px;
        }
      }
    }
    .logout-button {
      margin: 47px auto 0 auto;
      width: 349px;
      height: 61px;
      color: #ff0000;
      font-size: 15px;
      font-weight: bold;
      line-height: 61px;
      text-align: center;
      border-radius: 13px;
      border: 1px solid #ff0000;
    }
    .filings-wrapper {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      &-item {
        margin-bottom: 2px;
        &-title {
          color: #bbbbbb;
        }
        &-text {
          margin-left: 9px;
          color: #999999;
        }
      }
    }
    .dialog-container {
      font-size: 12px;
      color: #333333;
      text-align: left;
      div {
        margin: 14px;
      }
      div:nth-child(odd) {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
@media screen and (min-width: 501px) {
  .system-setting-container {
    img {
      user-select: none;
    }
    background-color: #fff;
    position: relative;
    height: 100vh;
    overflow-y: overlay;
    .option-list {
      margin-top: 9px;
      padding: 9px 17px 9px 30px;
      &-item {
        &:not(:last-child) {
          margin-bottom: 18px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        .item-left {
          display: flex;
          align-items: center;
          &-icon {
            width: 35px;
            height: 35px;
          }
          &-title {
            margin-left: 9px;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .item-right {
          width: 48px;
          height: 48px;
        }
      }
    }
    .logout-button {
      margin: 0px auto 0 auto;
      width: 349px;
      height: 61px;
      color: #ff0000;
      font-size: 15px;
      font-weight: bold;
      line-height: 61px;
      text-align: center;
      border-radius: 13px;
      border: 1px solid #ff0000;
    }
    .filings-wrapper {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      &-item {
        margin-bottom: 2px;
        &-title {
          color: #bbbbbb;
        }
        &-text {
          margin-left: 9px;
          color: #999999;
        }
      }
    }
    .dialog-container {
      font-size: 12px;
      color: #333333;
      text-align: left;
      div {
        margin: 14px;
      }
      div:nth-child(odd) {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

@primary-color: #1DA57A;