.voice-message{
    .voice-contont{
        display: flex;
        button{
            width: 100%;
            display: flex;
            border: none;
            justify-content: flex-start;
            align-items: center;
            span{
                margin-left: 10px;
            }
        }
    }
    .translate{
        padding: 5px 0 0 0;
    }
}
@primary-color: #1DA57A;