.points-mall-container {
  img {
    user-select: none;
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0, #f4f6ff 0%, #dbedff 100%);
  .mall-list-container {
    .mall-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &-content {
        position: relative;
        margin-left: 27px;
        .content-title {
          position: absolute;
          top: -60px;
          left: 0;
          font-size: 52px;
          font-weight: bold;
          color: #333333;
        }
        .content-text {
          margin-bottom: 38px;
          font-size: 16px;
          font-weight: bold;
          color: #666666;
        }
      }
      &-icon {
        width: 282px;
      }
    }
    padding: 0 18px;
    flex: 1;
    overflow-y: auto;
    .mall-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      &-item {
        margin-bottom: 15px;
        width: 192px;
        height: 270px;
        .item-pic {
          width: 192px;
          height: 192px;
          border-radius: 13px;
        }
        .item-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          margin-top: 7px;
          height: 41px;
          font-size: 14px;
          color: #333333;
        }
        .item-integral {
          margin-top: 4px;
          font-size: 15px;
          font-weight: bold;
          color: #ed1919;
        }
      }
      &-bottom {
        width: 100%;
        height: 40px;
        line-height: 30px;
        color: #999999;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

@primary-color: #1DA57A;