.word-collection-container {
  background-color: #f4f6ff;
  height: 100vh;
  overflow-y: overlay;
  .sort-tabs {
    padding: 15px 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-item {
      margin-right: 13px;
      padding: 4px 7px;
      color: #666666;
      font-size: 15px;
      &--select {
        color: #1955ff;
        background-color: #dee6ff;
        border-radius: 5px;
      }
    }
  }
  .word-list {
    padding: 0 18px 120px 18px;
    &-time {
      position: relative;
      padding: 13px 0px 13px 26px;
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 16px;
        width: 13px;
        height: 13px;
        border-radius: 6.5px;
        background-color: #1955ff;
      }
    }
    &-item {
      overflow: hidden;
      padding: 15px 18px 18px 18px;
      border-radius: 9px;
      background-color: #fff;
      .item-header {
        position: relative;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-text {
          font-size: 14px;
          font-weight: 700;
          color: rgba(0, 0, 0, 1);
        }
        &-icon {
          // margin-left: 24px;
        }
        &-select {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .item-audio {
        display: flex;
        align-items: center;
        &-text {
          display: inline-block;
          padding: 0 16px;
          height: 22px;
          line-height: 22px;
          font-size: 10px;
          color: #000000;
          border-radius: 11px;
          background-color: rgba(223, 234, 247, 1);
        }
        &-icon {
          margin-left: 8px;
          margin-right: 26px;
          width: 16px;
        }
      }
      .item-content {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
    }
  }
  .bottom-wrapper--unselect {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 9px 18px 0px 18px;
    box-sizing: border-box;
    width: 100%;
    height: 110px;
    background-color: #fff;
  }
  .bottom-wrapper--select {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 9px 14px 0px 14px;
    box-sizing: border-box;
    width: 100%;
    height: 110px;
    background-color: #fff;
    .check-wrapper {
      display: flex;
      align-items: flex-end;
      &-see {
        margin-left: 7px;
        font-size: 16px;
        line-height: 18px;
        color: #1955ff;
      }
    }
    :global(.rv-checkbox) {
      margin-top: 20px;
    }
    :global(.rv-checkbox__label) {
      font-size: 16px;
      color: #333333;
    }
    .export-button {
      width: 149px;
    }
  }
  .select-pop {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    &-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 439px;
      border-radius: 18px 18px 0px 0px;
      background-color: #fff;
      .header-title {
        margin-top: 22px;
        margin-left: 18px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .content {
        margin-top: 29px;
        width: 100%;
        height: 250px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        &-item {
          margin-left: 13px;
          margin-bottom: 22px;
          padding: 4px 7px;
          display: flex;
          align-items: center;
          color: #1955ff;
          background-color: #eaefff;
          border-radius: 5px;
          &-icon {
            margin-left: 7px;
            width: 13px;
            height: 13px;
          }
        }
      }
      .button-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 101px;
        padding: 0px 18px;
        .export-button {
          width: 135px;
          border: 1px solid #1955ff;
          background: #fff;
          color: #1955ff;
          font-weight: normal;
        }
        .close-button {
          width: 245px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;