// @media only screen and (max-width: 500px) {
.pay-container {
  height: 100vh;
  overflow-y: overlay;
  background: linear-gradient(#c4edff 0%, #ebf6ff 100%);
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  &.pay {
    background: linear-gradient(#fcbf63 0%, #ffedbb 100%);
  }
  .topBox {
    box-sizing: border-box;
    padding-top: 0.6rem;
    padding-left: 3%;
    height: 5rem;
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
    .topIcon1 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 52%;
    }
    .topIcon2 {
      position: absolute;
      bottom: 1.2rem;
      display: flex;
      .top1 {
        background: #4368ff;
        width: 1rem;
        aspect-ratio: 1;
        border-radius: 50%;
        display: block;
        z-index: 2;
      }
      .top2 {
        background: #aabeff;
        width: 1rem;
        aspect-ratio: 1;
        border-radius: 50%;
        display: block;
        transform: translateX(-50%);
        z-index: 1;
      }
    }
  }
  .goodsList {
    flex: 1;
    overflow-x: hidden;
    overflow-y: overlay;
    .goodItemBox {
      background: url("../../../assets/icon/img_ticket.png") no-repeat center
        center;
      background-size: 100% 100%;
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 139px;
      aspect-ratio: 2.8;
      .left {
        width: 120px;
        color: #ed1919;
        margin-left: 12px;
        box-sizing: border-box;
        flex-shrink: 0;
        // width: 30%;
        > div:nth-child(1) {
          font-size: 14px;
          margin-bottom: 0.2rem;
          // padding-left: 0.3rem;
        }
        > div:nth-child(2) {
          font-weight: bold;
          span:nth-child(1) {
            font-size: 14px;
            margin-right: 4px;
          }
          span:nth-child(2) {
            font-size: 22px;
          }
        }
      }
      .center {
        flex: 1;
        > div:nth-child(1) {
          font-size: 22px;
          font-weight: bold;
          color: var(--color2);
        }
        > div:nth-child(2) {
          font-size: 15px;
          color: var(--color2);
        }
      }
      .right {
        flex-shrink: 0;
        width: 88px;
        margin-right: 18px;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 88px;
          height: 31px;
          border-radius: 15px;
          color: #fff;
          background: linear-gradient(90deg, #ff5858 0%, #ff2525 100%);
          font-size: 15px;
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
    .phone-number {
      color: #666666;
      font-size: 15px;
      text-align: center;
    }
  }
  .pop-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 360px;
    height: 490px;
    background-color: rgba(245, 249, 255, 1);
    border-radius: 2px;
    &-icon {
      width: 120px;
    }
    &-result {
      margin-top: 48px;
      font-size: 24px;
      color: rgba(32, 34, 68, 1);
    }
    &-reason {
      margin-top: 16px;
      font-size: 14px;
      color: rgba(84, 84, 84, 1);
    }
    &-confirm {
      margin-top: 30px;
      width: 206px;
      background-color: rgba(9, 97, 245, 1);
    }
  }
  .exchange-button {
    padding: 20px 0;
    color: #5872ff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .phone-number {
    margin-bottom: 30px;
    font-size: 15px;
    text-align: center;
    color: #666666;
  }
}
.popupBox {
  padding: 1rem;
  box-sizing: border-box;
  .popupBox1 {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 1.6rem 1rem;
    justify-content: space-between;
    .left {
      > div:nth-child(1) {
        color: var(--color2);
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 6px;
        text-align: left;
      }
      > div:nth-child(2) {
        font-weight: 400;
        font-size: 13px;
        color: var(--color2);
        line-height: 18.5px;
        text-align: left;
      }
    }
    .right {
      span:nth-child(1) {
        color: #ed1919;
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
      }
      span:nth-child(2) {
        color: #ed1919;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }
  .agreement-box {
    padding-left: 9px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    .text-1 {
      margin-left: 7px;
      color: #000000;
    }
    .text-2 {
      color: rgba(42, 130, 228, 1);
    }
    .box-icon {
      position: relative;
      width: 16px;
      height: 16px;
      border: 1px solid rgba(196, 196, 196, 1);
      border-radius: 4px;
      background-color: #fff;
      &--checked {
        border: 1px solid rgba(42, 130, 228, 1);
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 9px;
          border: solid rgba(42, 130, 228, 1);
          border-width: 0 2px 2px 0;
          top: 1px;
          left: 5px;
          transform: rotate(35deg);
        }
      }
    }
  }
  .popupBox2 {
    .top {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: var(--color2);
      margin-top: 10px;
      margin-bottom: 18px;
    }
    .pays {
      box-sizing: border-box;
      padding-right: 1rem;
      .payItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.78rem 0;

        font-weight: 400;
        font-size: 15px;
        color: var(--color2);
        > div {
          display: flex;
          align-items: center;
          > img {
            width: 2.4rem;
            aspect-ratio: 1;
            margin-right: 0.86rem;
          }
        }
      }
    }
  }
  .popupBox3 {
    color: #fff;
    background: linear-gradient(90deg, #ff5858 0%, #ff2525 100%);
    text-align: center;
    border-radius: 0.6rem;
    padding: 0.8rem;
    font-size: 1.2rem;
    margin-top: 2rem;
    span:nth-child(1) {
      font-size: 0.8rem;
      margin-left: 0.2rem;
      margin-right: 0.1rem;
    }
    span:nth-child(2) {
      font-size: 1.3rem;
    }
  }
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .color-1 {
    color: #ff8b23;
  }
  .color-2 {
    color: #4f6fcc;
  }
  .one-cell {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .common-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 336px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .common-content-button {
    width: 154px;
    height: 49px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .content-1 {
    .common-content();
    height: 387px;
    background-image: url("/images/mine/pay/content-1.png");
    &-input {
      margin: 182px auto 57px auto;
      width: 256px;
      height: 66px;
      border-radius: 11px;
      border: 2px solid #4f6fcc;
      color: #4f6fcc;
      background-color: #e4edfe;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
    }
    &-confirm {
      .common-content-button();
      background-image: url("/images/mine/pay/confirm-button.png");
    }
  }
  .content-2 {
    .common-content();
    height: 445px;
    background-image: url("/images/mine/pay/content-2.png");
    &-title {
      margin-top: 130px;
      color: #95390e;
      font-size: 18px;
      font-weight: bold;
    }
    &-text {
      margin: 39px auto 24px auto;
      font-size: 16px;
      font-weight: bold;
    }
    &-content {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 252px;
      height: 106px;
      border-radius: 11px;
      background-color: rgba(79, 111, 204, 0.1);
      box-sizing: border-box;
      padding: 18px 21px;
      color: #4f6fcc;
      font-size: 16px;
      font-weight: bold;
    }
    &-confirm {
      .common-content-button();
      background-image: url("/images/mine/pay/submit-button.png");
    }
  }
  .common-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 252px;
    background-color: rgba(79, 111, 204, 0.1);
    border-radius: 11px;
    font-size: 19px;
    font-weight: bold;
  }
  .content-3 {
    height: 410px;
    background-image: url("/images/mine/pay/content-3.png");
    .common-content();
    &-prompt {
      .common-prompt();
      margin-top: 230px;
      padding: 25px 21px;
      box-sizing: border-box;
      margin-right: 20px;
    }
  }
  .content-4 {
    height: 389px;
    background-image: url("/images/mine/pay/content-4.png");
    .common-content();
    &-prompt {
      .common-prompt();
      margin-top: 200px;
      padding: 25px 21px;
      box-sizing: border-box;
    }
  }
  &-close {
    margin-top: 33px;
    width: 24px;
    height: 24px;
  }
}
// }
// @media screen and (min-width: 501px) {
//   .pay-container {
//     padding: 0 60px;
//     .goods-list {
//       &-item {
//         margin-top: 35px;
//         background-color: #fff;
//         border-radius: 2px;
//         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
//         .item-header {
//           padding: 6px 23px;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           font-size: 12px;
//         }
//         .item-body {
//           width: 308px;
//           vertical-align: bottom;
//         }
//         &:first-child {
//           margin-top: 0;
//         }
//       }
//     }
//     .agree-wrapper {
//       padding: 21px 18px;
//       display: flex;
//       align-items: center;
//       &-text {
//         margin-left: 5px;
//         color: rgba(84, 84, 84, 1);
//         font-size: 13px;
//         .link {
//           color: #193bdb;
//         }
//       }
//     }
//     .way-list {
//       &-top {
//         margin: 18px 0;
//         font-size: 12px;
//         color: rgba(84, 84, 84, 1);
//       }
//       &-item {
//         margin-bottom: 12px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         padding: 0 18px;
//         height: 50px;
//         background-color: #fff;
//         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
//         .left {
//           display: flex;
//           align-items: center;
//           &-icon {
//             width: 32px;
//             user-select: none;
//           }
//           &-text {
//             margin-left: 21px;
//             font-size: 12px;
//             font-weight: 700;
//             color: rgba(32, 34, 68, 1);
//           }
//         }
//       }
//     }
//     .pay-button {
//       position: absolute;
//       bottom: 93px;
//       left: 60px;
//       width: calc(100% - 120px);
//       background-color: rgba(9, 97, 245, 1);
//       box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
//     }
//     .pop-content {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       width: 360px;
//       height: 490px;
//       background-color: rgba(245, 249, 255, 1);
//       border-radius: 2px;
//       &-icon {
//         width: 120px;
//       }
//       &-result {
//         margin-top: 48px;
//         font-size: 24px;
//         color: rgba(32, 34, 68, 1);
//       }
//       &-reason {
//         margin-top: 16px;
//         font-size: 14px;
//         color: rgba(84, 84, 84, 1);
//       }
//       &-confirm {
//         margin-top: 30px;
//         width: 206px;
//         background-color: rgba(9, 97, 245, 1);
//       }
//     }
//     :global(.rv-overlay) {
//       background-color: rgba(32, 34, 68, 0.8);
//     }
//   }
// }

@primary-color: #1DA57A;