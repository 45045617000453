.font {
  font-size: 9px;
  font-family: MulishExtraBold;
}
.rv-tabbar {
  height: 80px;
  overflow: hidden;
  background-image: url("/images/mine/img_bottom_bar.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  align-items: flex-start;
}
.rv-tabbar-item {
  margin-top: 15px;
  color: #333333;
  font-size: 13px;
  // font-weight: 700;
  font-family: MulishMedium;
  background: none;
}
.rv-tabbar-item--active {
  color: #1955ff;
  background: none;
}
.rv-tabbar-item__icon {
  margin-bottom: 8px;
  font-size: 26px;
}
// 原颜色 #363DFF

@primary-color: #1DA57A;