.points-center-container {
  img {
    user-select: none;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 18px;
  height: 100vh;
  background: linear-gradient(0, #f4f6ff 0%, #ccd2ff 100%);
  .right-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 188px;
  }
  .points-header {
    position: relative;
    margin-top: 9px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      .left-title {
        font-size: 52px;
        font-weight: bold;
        color: #000000;
      }
      .left-text {
        margin-left: 9px;
        font-size: 16px;
        font-weight: bold;
        color: #666666;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 35px;
      background: linear-gradient(115deg, #fde49a 0%, #ffc06d 100%);
      border-radius: 20px;
      .right-icon {
        margin-right: 2px;
        width: 22px;
        height: 22px;
      }
      .right-text {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .points-jump {
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      width: 49%;
    }
  }
  .points-task-list {
    margin-top: 13px;
    padding-bottom: 13px;
    flex: 1;
    overflow-y: auto;
    .common-list {
      margin-bottom: 13px;
      padding: 13px 22px 24px 22px;
      background-color: #fff;
      border-radius: 13px;
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        .tab-item {
          position: relative;
          font-size: 18px;
          color: #999999;
          &--select {
            color: #333333;
            font-weight: bold;
            &:after {
              content: "";
              position: absolute;
              bottom: -4px;
              left: calc(50% - 9px);
              width: 18px;
              height: 4px;
              border-radius: 13px;
              background: linear-gradient(90deg, #adbaff 0%, #809aff 100%);
            }
          }
          &:first-child {
            margin-right: 79px;
          }
        }
      }
      &-item {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-icon {
            width: 48px;
            height: 48px;
          }
          &-content {
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .content-title {
              font-size: 15px;
              font-weight: bold;
              color: #333333;
            }
            .content-text {
              margin-top: 2px;
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #999999;
              &-icon {
                margin-right: 2px;
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .item-right--finish {
          flex-shrink: 0;
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #ffb62b;
          .finish-icon {
            margin-right: 4px;
            width: 18px;
            height: 18px;
          }
        }
        .item-right--unfinish {
          flex-shrink: 0;
          width: 79px;
          height: 35px;
          border-radius: 18px;
          font-size: 14px;
          font-weight: bold;
          background-color: #fff2ea;
          color: #fb3904;
          line-height: 35px;
          text-align: center;
        }
      }
      &-line {
        margin-top: 12px;
        padding: 13px 44px 8px 44px;
        background-color: #fafafa;
        border-radius: 13px;
        .line-top {
          position: relative;

          &-back {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 18px;
            width: calc(100% - 36px);
            height: 2px;
            background-color: #eeeeee;
            &-finish {
              width: 18px;
              height: 18px;
            }
            &-unfinish {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #eeeeee;
            }
          }
        }
        .line-bottom {
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          color: #aaaaaa;
          &-text--finish {
            color: #ffb62b;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;