.audio-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 17px;
  width: 114px;
  height: 35px;
  border-radius: 17px;
  &--yellow {
    background-color: #ffc200;
  }
  &--blue {
    background-color: #0961f5;
  }
  .play-button {
    margin-left: 11px;
    width: 25px;
  }
  .sound {
    margin-left: 23px;
    width: 30px;
  }
}

@primary-color: #1DA57A;