.douyin-container {
  img {
    user-select: none;
  }
  width: 100%;
  height: 100vh;
  background-size: 100% auto;
  background-image: url("/images/douyin/back.png");
  background-repeat: no-repeat;
  background-color: #ebf6ff;
  overflow-x: hidden;
  overflow-y: overlay;
  .header {
    padding: 0 18px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      font-size: 40px;
      font-weight: bold;
      color: #136587;
    }
    &-ubao {
      margin-right: 36px;
      width: 102px;
    }
  }
  .content {
    margin: 0 auto;
    padding: 25px 18px;
    width: 393px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 13px;
    &-title {
      position: relative;
      margin-bottom: 19px;
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: -17px;
        width: 3px;
        height: 8px;
        border-radius: 2px;
        background-color: #1dc3ff;
      }
    }
    &-card {
      margin-bottom: 25px;
      padding-left: 25px;
      width: 100%;
      box-sizing: border-box;
      height: 64px;
      border-radius: 5px;
      color: #333333;
      font-size: 18px;
      border: none;
      background-color: #f7f7f7;
    }
    &-phone {
      margin-bottom: 18px;
      position: relative;
      width: 100%;
      height: 64px;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 25px;
      border-radius: 5px;
      &-icon {
        width: 30px;
      }
      &-input {
        margin-left: 18px;
        width: 200px;
        height: 100%;
        border: none;
        background-color: transparent;
        &::placeholder {
          color: #333333;
        }
      }
      &-clear {
        position: absolute;
        top: 10px;
        right: 0;
        width: 42px;
      }
      &--selected {
        box-shadow: 0px 2px 5px 0px rgba(74, 74, 74, 0.3);
      }
    }
    &-code {
      position: relative;
      width: 100%;
      height: 64px;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 25px;
      border-radius: 5px;
      &-icon {
        width: 30px;
      }
      &-input {
        margin-left: 18px;
        width: 150px;
        height: 100%;
        border: none;
        background-color: transparent;
        &::placeholder {
          color: #333333;
        }
      }
      &-message {
        position: absolute;
        top: 21px;
        right: 18px;
        color: #1955ff;
        font-size: 16px;
        height: 22px;
        line-height: 22px;
      }
      &--selected {
        box-shadow: 0px 2px 5px 0px rgba(74, 74, 74, 0.3);
      }
    }
  }
  .confirm-button {
    margin: 34px auto 0;
    width: 230px;
    height: 65px;
    background-size: 100% auto;
    background-image: url("/images/douyin/confirm-button.png");
    background-repeat: no-repeat;
    font-size: 20px;
    font-weight: bold;
    color: #a85512;
    line-height: 65px;
    text-align: center;
  }
  .prompt {
    padding: 0 19px;
    margin-top: 39px;
    &-title {
      font-size: 18px;
      color: #333333;
    }
    &-text {
      margin-top: 19px;
      color: #999999;
      font-size: 14px;
    }
  }
}
.pop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .success-pop {
    width: 336px;
    height: 385px;
    background-size: 100% auto;
    background-image: url("/images/douyin/success.png");
    background-repeat: no-repeat;
    &-text {
      margin-right: 20px;
      margin-top: 270px;
      color: #008bc5;
      font-size: 20px;
      line-height: 33px;
      text-align: center;
    }
  }
  .fail-pop {
    width: 336px;
    height: 319px;
    background-size: 100% auto;
    background-image: url("/images/douyin/fail.png");
    background-repeat: no-repeat;
    &-text {
      margin-top: 200px;
      color: #008bc5;
      font-size: 20px;
      line-height: 33px;
      text-align: center;
    }
  }
  .close-icon {
    margin-top: 31px;
    width: 25px;
    height: 25px;
  }
}

@primary-color: #1DA57A;