.course-container {
  overflow: hidden;
  height: 100vh;
  padding-top: 61px;
  box-sizing: border-box;
  .tab-container {
    :global(.rv-tabs__wrap) {
      padding: 0 26px;
      z-index: 100;
      background-color: #fff;
      :global(.rv-tabs__nav) {
        padding: 0;
        overflow-x: overlay;
        :global(.rv-tab) {
          flex: none;
          padding: 0 0 4px 0;
          margin-right: 18px;
          :global(.rv-tab__text) {
            padding: 2px 0;
            font-size: 20px;
            color: #979797;
          }
        }
        :global(.rv-tab--active) {
          :global(.rv-tab__text) {
            color: var(--color2);
            font-size: 24px !important;
            transform: translateY(-0.1rem);
          }
        }
        :global(.rv-tab--active::after) {
          content: "";
          width: 15px;
          height: 7px;
          background: #1955ff;
          border-radius: 4px;
          position: absolute;
          background-color: var(--primyColor1);
          left: 0;
          bottom: 0px;
        }
        :global(.rv-tabs__line) {
          background-color: transparent;
        }
      }
    }
  }
}

@primary-color: #1DA57A;