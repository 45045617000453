.home-context {
  background: url(../../assets/images/myBg.png) no-repeat top right;
  background-size: 50%;
  background-color: var(--globalBg_1);
  box-sizing: border-box;
  padding: 56px 18px 0px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: overlay;
  .rv-product-card {
    padding: 0;
  }
}

.kf{
  position: absolute;
  right: 0;
  bottom: 120px;
  z-index: 999;

  .img{
    width: 88px;
    height:88px;
    background: url("/images/image/index_kf.png") no-repeat;
    background-size: cover;
  }

  .text{
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #505990;
    font-size: 14px;
  }
}



@primary-color: #1DA57A;