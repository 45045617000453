.log-info-container {
  background: linear-gradient(0, #f4f6ff 0%, #dbdfff 100%);
  img {
    user-select: none;
  }
  display: flex;
  flex-direction: column;
  height: 100vh;
  .log-list {
    padding: 4px 18px;
    flex: 1;
    overflow-y: auto;
    .info-wrapper {
      padding: 14px 18px;
      border-radius: 13px;
      background-color: #fff;
      &-title {
        margin-bottom: 9px;
        color: #ff8700;
        font-size: 20px;
        font-weight: bold;
        &--status1 {
          color: #ff8700;
        }
        &--status2 {
          color: #1955ff;
        }
        &--status3 {
          color: #999999;
        }
      }
      &-text {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        color: #333333;
        font-size: 14px;
        &-icon {
          margin-left: 13px;
          width: 13px;
          height: 13px;
        }
      }
      &-good {
        padding: 9px;
        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 11px;
        .good-pic {
          width: 66px;
          height: 66px;
          object-fit: contain;
          flex-shrink: 0;
          margin-right: 9px;
          border-radius: 11px;
        }
        .good-title {
          font-size: 15px;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }
    .status-list {
      margin-top: 13px;
      margin-bottom: 13px;
      padding: 22px 29px;
      background-color: #fff;
      border-radius: 13px;
      &-item {
        position: relative;
        padding-left: 19px;
        border-left: 1px solid #1955ff;
        &:before {
          position: absolute;
          top: 0px;
          left: -7px;
          content: "";
          width: 13px;
          height: 13px;
          border-radius: 6.5px;
          background-color: #1955ff;
        }
        &:last-child {
          border-left-color: #ffffff;
        }
        .item-top {
          display: flex;
          align-items: center;
          &-title {
            margin-right: 13px;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
          &-time {
            color: #999999;
            font-size: 13px;
          }
        }
        .item-content {
          margin-top: 4px;
          padding-bottom: 22px;
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;