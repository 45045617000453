.search-list-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  overflow: hidden;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--globalBg);
    padding-top: 40px;
    &-back {
      display: flex;
      align-items: center;
      width: 30px;
      height: 100%;
    }
    &-input {
      padding-left: 10px;
      width: calc(100% - 120px);
      height: 30px;
      border: none;
      border-radius: 15px;
      background-color: #fff;
    }
    &-search {
      margin-left: 20px;
    }
  }
  .history {
    position: relative;
    margin: 0 auto;
    width: calc(100% - 20px);
    &-delete {
      position: absolute;
      top: 0;
      right: 0;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      &-item {
        margin: 8px;
        padding: 8px;
        background-color: var(--globalBg);
      }
    }
  }
}

@primary-color: #1DA57A;