.logoff-container {
  background-color: #fff;
  height: 100vh;
  .risk-img {
    display: block;
    margin: 20px auto 0;
    width: 80px;
    user-select: none;
  }
  .risk-title {
    text-align: center;
  }
  .des-wrapper {
    padding: 30px;
    p {
      font-size: 14px;
    }
    .off-button {
      margin-top: 60px;
      margin-bottom: 20px;
    }
    .off-check {
      font-size: 12px;
      justify-content: center;
    }
  }
}

@primary-color: #1DA57A;