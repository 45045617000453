.member-pay-container {
  img {
    user-select: none;
  }
  position: relative;
  background-color: #01aeff;
  .container-back {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 797px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("/images/member-pay/back.png");
    overflow: hidden;
  }
  .content {
    // position: absolute;
    // top: 304px;
    // left: 37px;
    margin: 304px auto 0;
    width: 353px;
    height: 395px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("/images/member-pay/content.png");
    overflow: hidden;
    &-title {
      margin-top: 18px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      color: #c35700;
    }
    &-box {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding-top: 20px;
      overflow: hidden;
      &-item {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 143px;
        background: #ffffff;
        box-shadow: 0px 0px 8px 1px rgba(0, 188, 241, 0.36);
        border-radius: 11px;
        box-sizing: border-box;
        overflow: hidden;
        &:nth-child(1) {
          margin-right: 16px;
        }
        .item-text-1 {
          margin-top: 12px;
          color: #666666;
          font-size: 18px;
          font-weight: 500;
        }
        .item-text-2 {
          color: #666666;
          font-size: 13px;
          font-weight: 500;
        }
        .item-text-3 {
          margin-top: 4px;
          margin-left: 5px;
          color: #333333;
          font-size: 27px;
          font-weight: bold;
        }
        .item-text-4 {
          font-size: 14px;
        }
        .item-text-5 {
          margin-top: 4px;
          color: #999999;
          font-size: 13px;
          text-decoration: line-through;
        }
        .item-text-6 {
          margin-top: 10px;
          width: 100%;
          height: 29px;
          background-color: #fda51b;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          line-height: 29px;
        }
        &--selected {
          border: 2px solid #fda51b;
        }
      }
    }
  }
  .phone-wrapper {
    position: absolute;
    top: 710px;
    left: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 353px;
    height: 79px;
    border-radius: 22px;
    background: #dffcff;
    &-title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    &-input {
      width: 187px;
      height: 38px;
      border: none;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 11px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  .bottom-block {
    height: 130px;
    width: 100%;
  }
  .bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 124px;
    background: #eaf8ff;
    overflow: hidden;
    &-button {
      margin: 10px auto 0;
      width: 343px;
      height: 61px;
      background: linear-gradient(-82deg, #01aeff 0%, #8cdaff 100%);
      border-radius: 11px;
      color: #007eb9;
      text-align: center;
      line-height: 61px;
      color: 20px;
      font-weight: bold;
    }
    .agreement-box {
      margin-top: 20px;
      padding-left: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      .text-1 {
        margin-left: 7px;
        color: #000000;
      }
      .text-2 {
        color: rgba(42, 130, 228, 1);
      }
      .box-icon {
        position: relative;
        width: 16px;
        height: 16px;
        border: 1px solid rgba(196, 196, 196, 1);
        border-radius: 4px;
        background-color: #fff;
        &--checked {
          border: 1px solid rgba(42, 130, 228, 1);
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 9px;
            border: solid rgba(42, 130, 228, 1);
            border-width: 0 2px 2px 0;
            top: 1px;
            left: 5px;
            transform: rotate(35deg);
          }
        }
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(5, 1, 0, 0.8);
    &-success {
      width: 356px;
      height: 426px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("/images/member-pay/modal-success.png");
      &-title {
        padding-left: 40px;
        padding-right: 12px;
        margin-top: 280px;
        font-size: 27px;
        font-weight: bold;
        color: #008bc5;
        text-align: center;
      }
      &-text {
        padding-left: 30px;
        padding-right: 20px;
        margin-top: 36px;
        font-size: 16px;
        font-weight: 500;
        color: #008bc5;
        text-align: center;
      }
    }
    &-fail {
      width: 336px;
      height: 354px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("/images/member-pay/modal-fail.png");
      &-title {
        padding-left: 30px;
        padding-right: 12px;
        margin-top: 200px;
        font-size: 27px;
        font-weight: bold;
        color: #008bc5;
        text-align: center;
      }
      &-text {
        padding-left: 30px;
        padding-right: 20px;
        margin-top: 36px;
        font-size: 16px;
        font-weight: 500;
        color: #008bc5;
        text-align: center;
      }
    }
    &-close {
      margin-top: 34px;
      margin-bottom: 100px;
      width: 24px;
      height: 24px;
    }
  }
}

@primary-color: #1DA57A;