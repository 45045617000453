:root {
  --old_background: #f5f9ff;
  --globalBg: #fff;
  --title_h3_color: #969fd4;
  --w: 40px;
  --r: 14px;
  --globalBg_1: #eaeeff;
  --marginTop: 13px;

  --primyColor1: #1955ff;
  --primyColor2: #5872ff;
  --primyColor3: #809aff;
  --primyColor4: #adbaff;
  --primyColor5: #d4dfff;
  --primyColor6: #ff8700;
  --primyColor7: #079dab;
  --primyColor8: #ed1919;

  --color1: #202244;
  --color2: #333333;
  --color3: #666666;
  --color4: #999999;
  --color5: #aaaaaa;
  --color6: #bbbbbb;
  --color7: #cccccc;
  --color8: #dddddd;
  --color9: #eeeeee;
  --color0: #f5f5f5;
}
html,
body {
  overflow-x: hidden;
  user-select: none;
}
#root {
  background: var(--globalBg);
  /* font-family: PingFangHK; */
}
.block {
  height: 1.8rem;
  width: 100%;
}
.double-block {
  height: 2rem;
  width: 100%;
}
.globalbg {
  background-color: var(--globalBg);
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.divider {
  margin-bottom: var(--marginTop);
  width: 100%;
  height: 0;
}
.top-menu {
  /* > div {
    > .rv-sticky { */
  > .rv-tabs__wrap {
    padding: 0 7px;
    z-index: 100;
    background-color: #fff;
    > .rv-tabs__nav {
      padding: 0;
      > .rv-tab {
        flex: none;
        padding: 0 0 4px 0;
        margin-right: 18px;
        > .rv-tab__text {
          padding: 2px 0;
          font-size: 20px;
          color: #979797;
        }
        &.rv-tab--active {
          > .rv-tab__text {
            color: var(--color2);
            font-size: 24px !important;
            transform: translateY(-0.1rem);
          }
          &::after {
            content: "";
            width: 15px;
            height: 7px;
            background: #1955ff;
            border-radius: 4px;
            position: absolute;
            background-color: var(--primyColor1);
            left: 0;
            bottom: 0px;
          }
        }
      }
      > .rv-tabs__line {
        background-color: transparent;
      }
    }
  }
  /* }
  } */
}

@primary-color: #1DA57A;