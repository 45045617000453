@media only screen and (max-width: 500px) {
  .welcome-container {
    overflow: hidden;
    .schedule-container {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 30px;
      width: 100%;
      z-index: 100;
      .item {
        margin: 0 5px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgba(253, 253, 253, 0.5);
        &--selected {
          width: 32px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
        }
      }
      &--deep {
        .item {
          background-color: rgba(90, 167, 255, 1);
          &--selected {
            width: 32px;
            height: 10px;
            border-radius: 5px;
            background-color: rgba(42, 129, 227, 1);
          }
        }
      }
    }
    .welcome-list {
      width: 400%;
      display: flex;
      .page-0 {
        flex-shrink: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("/images/image/welcome-back.png");
        transition: transform 0.5s ease;
        &-skip {
          position: absolute;
          top: 74px;
          right: 47px;
          color: #fff;
          font-size: 14px;
        }
        &-next {
          position: fixed;
          bottom: 70px;
          left: calc(50% - 40px);
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("/images/image/welcome-start.png");
        }
        &-loading {
          position: absolute;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
        }
        &-text--cn {
          position: absolute;
          bottom: 218px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        &-text--en {
          position: absolute;
          bottom: 189px;
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #fff;
        }
        &-video {
          display: block;
          margin: 200px auto;
          width: 350px;
          height: 200px;
          border-radius: 10px;
        }
      }
      .page-1 {
        flex-shrink: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("/images/image/welcome-back.png");
        transition: transform 0.5s ease;
        &-skip {
          position: absolute;
          top: 74px;
          right: 47px;
          color: #fff;
          font-size: 14px;
        }
        &-next {
          position: fixed;
          bottom: 70px;
          left: calc(50% - 40px);
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("/images/image/welcome-start.png");
        }
        &-loading {
          position: absolute;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
        }
        &-text--cn {
          position: absolute;
          bottom: 218px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        &-text--en {
          position: absolute;
          bottom: 189px;
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #fff;
        }
        &-center {
          position: absolute;
          top: 250px;
          left: 39px;
          width: 350px;
          user-select: none;
        }
      }
      .page-2 {
        padding: 55px 40px 72px;
        flex-shrink: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background-color: rgba(245, 249, 255, 1);
        transition: transform 0.5s ease;
        .top {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-back {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: rgba(125, 186, 251, 0.4);
            border-radius: 10px;
          }
          &-text {
            margin-left: 15px;
            font-size: 24px;
            font-weight: 700;
            color: rgba(42, 130, 228, 1);
          }
        }
        .text-style-1 {
          margin-top: 29px;
          font-weight: 700;
          font-size: 36px;
          color: rgba(42, 130, 228, 1);
        }
        .text-style-2 {
          font-size: 20px;
          font-weight: 700;
          color: rgba(255, 174, 0, 1);
        }
        .level-list {
          height: calc(100vh - 306px);
          overflow-x: hidden;
          overflow-y: auto;
          &-item {
            margin: 25px auto 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 348px;
            height: 104px;
            border-radius: 13px;
            box-sizing: border-box;
            border: 1px solid rgba(178, 192, 211, 0.3);
            .left {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 87px;
              height: 91px;
              color: rgba(42, 129, 227, 1);
              font-size: 24px;
              font-weight: 700;
              flex-shrink: 0;
            }
            .right {
              margin-left: 20px;
              &-text {
                position: relative;
                margin-bottom: 3.4px;
                color: rgba(42, 130, 228, 1);
                font-size: 12px;
                font-weight: 700;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -12px;
                  transform: translate(0, -50%);
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: rgba(204, 204, 204, 1);
                }
              }
            }
            &--selected {
              background-color: rgba(42, 129, 227, 1);
              .left {
                background-color: #fff;
                border-radius: 13px;
              }
              .right {
                &-text {
                  color: #fff;
                  &::before {
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
      .page-3 {
        position: relative;
        padding: 55px 40px 72px;
        flex-shrink: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background-color: rgba(245, 249, 255, 1);
        transition: transform 0.5s ease;
        .top {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-back {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: rgba(125, 186, 251, 0.4);
            border-radius: 10px;
          }
          &-text {
            margin-left: 15px;
            font-size: 24px;
            font-weight: 700;
            color: rgba(42, 130, 228, 1);
          }
        }
        .text-style-1 {
          margin-top: 30px;
          font-size: 36px;
          font-weight: 700;
          color: rgba(42, 129, 227, 1);
        }
        .text-style-2 {
          font-size: 18px;
          font-weight: 700;
          color: rgba(42, 129, 227, 1);
        }
        .book-list {
          height: calc(100% - 250px);
          overflow-x: hidden;
          overflow-y: auto;
          &-item {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 348px;
            height: 104px;
            border-radius: 13px;
            box-sizing: border-box;
            border: 3px solid #fff;
            background-color: #fff;
            .left {
              margin-left: 8px;
              width: 70px;
              height: 90px;
              flex-shrink: 0;
            }
            .right {
              margin-left: 26px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 90px;
              &-top {
                font-size: 18px;
                font-weight: 700;
                color: rgba(42, 130, 228, 1);
              }
              &-center {
                font-size: 14px;
                font-weight: 700;
                color: rgba(128, 128, 128, 1);
              }
              &-bottom {
                font-size: 16px;
                font-weight: 400;
                color: rgba(134, 142, 157, 1);
              }
            }
            &--selected {
              border: 3px solid rgba(42, 130, 228, 1);
            }
          }
        }
        .next-step {
          position: absolute;
          bottom: 127px;
          left: calc(50% - 146px);
          width: 292px;
          height: 56px;
          background-color: rgba(42, 129, 227, 1);
          font-size: 24px;
          font-weight: 700;
          color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
  :global(.rv-popup) {
    border-radius: 0.125rem;
    background-color: rgba(245, 249, 255, 1);
  }
  :global(.rv-overlay) {
    background-color: rgba(32, 34, 68, 0.8);
  }
  .pop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 360px;
    height: 460px;
    .pop-avatar {
      margin-top: 93px;
      height: 117px;
      vertical-align: bottom;
      object-fit: contain;
    }
    .pop-title {
      margin: 20px 0 10px;
      color: rgba(56, 56, 56, 1);
      font-size: 12px;
      font-weight: 400;
    }
    .pop-text {
      color: rgba(56, 56, 56, 1);
      font-size: 16px;
      font-weight: 400;
    }
    .pop-input {
      margin-top: 21px;
      width: 205px;
      height: 42px;
      color: #000;
      font-size: 20px;
      font-weight: 400;
      background-color: rgba(229, 229, 229, 1);
      border-radius: 2px;
      border: none;
      text-align: center;
    }
    .pop-button {
      margin-top: 26px;
      width: 205px;
      height: 42px;
      background-color: rgba(9, 97, 245, 1);
      border-radius: 2px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
    .pop-loading {
      margin-top: 1.5rem;
    }
    .pop-img--1 {
      position: absolute;
      top: 4.875rem;
      left: 5.75rem;
      width: 0.75rem;
    }
    .pop-img--2 {
      position: absolute;
      top: 3.125rem;
      left: 8.75rem;
      width: 1.562rem;
    }
    .pop-img--3 {
      position: absolute;
      top: 2.562rem;
      left: 7.187rem;
      width: 1.562rem;
    }
    .pop-img--4 {
      position: absolute;
      top: 5.625rem;
      left: 3.375rem;
      width: 0.5rem;
    }
    .pop-img--5 {
      position: absolute;
      top: 6.75rem;
      left: 16.437rem;
      width: 0.75rem;
    }
    .pop-img--6 {
      position: absolute;
      top: 8.875rem;
      left: 2.5rem;
      width: 1.125rem;
    }
    .pop-img--7 {
      position: absolute;
      top: 3.562rem;
      left: 16.437rem;
      width: 1.125rem;
    }
    .pop-img--8 {
      position: absolute;
      top: 13.5rem;
      left: 2.5rem;
      width: 0.875rem;
    }
    .pop-img--9 {
      position: absolute;
      top: 8rem;
      left: 19.062rem;
      width: 0.875rem;
    }
  }
}

@media screen and (min-width: 501px) {
  .welcome-container {
    overflow: hidden;
    .schedule-container {
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 30px;
      width: 100%;
      z-index: 100;
      .item {
        margin: 0 5px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgba(253, 253, 253, 0.5);
        &--selected {
          width: 32px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
        }
      }
      &--deep {
        .item {
          background-color: rgba(90, 167, 255, 1);
          &--selected {
            width: 32px;
            height: 10px;
            border-radius: 5px;
            background-color: rgba(42, 129, 227, 1);
          }
        }
      }
    }
    .welcome-list {
      width: 400%;
      display: flex;
      .page-0 {
        flex-shrink: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("/images/image/welcome-back-pad.png");
        transition: transform 0.5s ease;
        &-skip {
          position: absolute;
          top: 44px;
          right: 47px;
          color: #fff;
          font-size: 14px;
        }
        &-next {
          position: fixed;
          bottom: 60px;
          left: calc(50% - 40px);
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("/images/image/welcome-start.png");
        }
        &-loading {
          position: absolute;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
        }
        &-text--cn {
          position: absolute;
          bottom: 218px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        &-text--en {
          position: absolute;
          bottom: 189px;
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #fff;
        }
        &-video {
          display: block;
          margin: 200px auto;
          width: 350px;
          height: 200px;
          border-radius: 10px;
        }
      }
      .page-1 {
        flex-shrink: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("/images/image/welcome-back-pad.png");
        transition: transform 0.5s ease;
        &-skip {
          position: absolute;
          top: 44px;
          right: 47px;
          color: #fff;
          font-size: 14px;
        }
        &-next {
          position: fixed;
          bottom: 60px;
          left: calc(50% - 40px);
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("/images/image/welcome-start.png");
        }
        &-loading {
          position: absolute;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
        }
        &-text--cn {
          position: absolute;
          bottom: 218px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
        }
        &-text--en {
          position: absolute;
          bottom: 189px;
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #fff;
        }
        &-center {
          position: absolute;
          top: 50px;
          left: 39px;
          width: 350px;
          user-select: none;
        }
      }
      .page-2 {
        padding: 55px 40px 72px;
        flex-shrink: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background-color: rgba(245, 249, 255, 1);
        transition: transform 0.5s ease;
        .top {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-back {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: rgba(125, 186, 251, 0.4);
            border-radius: 10px;
          }
          &-text {
            margin-left: 15px;
            font-size: 24px;
            font-weight: 700;
            color: rgba(42, 130, 228, 1);
          }
        }
        .text-style-1 {
          margin-top: 30px;
          font-size: 39px;
          color: rgba(42, 130, 228, 1);
        }
        .text-style-2 {
          font-size: 20px;
          font-weight: 700;
          color: rgba(255, 174, 0, 1);
        }
        .level-list {
          height: calc(100vh - 306px);
          overflow-x: hidden;
          overflow-y: auto;
          &-item {
            margin: 25px auto 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 348px;
            height: 104px;
            border-radius: 13px;
            box-sizing: border-box;
            border: 1px solid rgba(178, 192, 211, 0.3);
            .left {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 87px;
              height: 91px;
              color: rgba(42, 129, 227, 1);
              font-size: 24px;
              font-weight: 700;
              flex-shrink: 0;
            }
            .right {
              margin-left: 20px;
              &-text {
                position: relative;
                margin-bottom: 3.4px;
                color: rgba(42, 130, 228, 1);
                font-size: 12px;
                font-weight: 700;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -12px;
                  transform: translate(0, -50%);
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: rgba(204, 204, 204, 1);
                }
              }
            }
            &--selected {
              background-color: rgba(42, 129, 227, 1);
              .left {
                background-color: #fff;
                border-radius: 13px;
              }
              .right {
                &-text {
                  color: #fff;
                  &::before {
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
      .page-3 {
        position: relative;
        padding: 55px 40px 72px;
        flex-shrink: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background-color: rgba(245, 249, 255, 1);
        transition: transform 0.5s ease;
        .top {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-back {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: rgba(125, 186, 251, 0.4);
            border-radius: 10px;
          }
          &-text {
            margin-left: 15px;
            font-size: 24px;
            font-weight: 700;
            color: rgba(42, 130, 228, 1);
          }
        }
        .text-style-1 {
          margin-top: 30px;
          font-size: 36px;
          font-weight: 700;
          color: rgba(42, 129, 227, 1);
        }
        .text-style-2 {
          font-size: 18px;
          font-weight: 700;
          color: rgba(42, 129, 227, 1);
        }
        .book-list {
          height: calc(100vh - 306px);
          overflow-x: hidden;
          overflow-y: auto;
          &-item {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 348px;
            height: 104px;
            border-radius: 13px;
            box-sizing: border-box;
            border: 3px solid #fff;
            background-color: #fff;
            .left {
              margin-left: 8px;
              width: 70px;
              height: 90px;
              flex-shrink: 0;
            }
            .right {
              margin-left: 26px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 90px;
              &-top {
                font-size: 18px;
                font-weight: 700;
                color: rgba(42, 130, 228, 1);
              }
              &-center {
                font-size: 14px;
                font-weight: 700;
                color: rgba(128, 128, 128, 1);
              }
              &-bottom {
                font-size: 16px;
                font-weight: 400;
                color: rgba(134, 142, 157, 1);
              }
            }
            &--selected {
              border: 3px solid rgba(42, 130, 228, 1);
            }
          }
        }
        .next-step {
          position: absolute;
          bottom: 50px;
          left: calc(50% - 146px);
          width: 292px;
          height: 56px;
          background-color: rgba(42, 129, 227, 1);
          font-size: 24px;
          font-weight: 700;
          color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
  :global(.rv-popup) {
    border-radius: 0.125rem;
    background-color: rgba(245, 249, 255, 1);
  }
  :global(.rv-overlay) {
    background-color: rgba(32, 34, 68, 0.8);
  }
  .pop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 360px;
    height: 460px;
    .pop-avatar {
      margin-top: 93px;
      height: 117px;
      vertical-align: bottom;
      object-fit: contain;
    }
    .pop-title {
      margin: 20px 0 10px;
      color: rgba(56, 56, 56, 1);
      font-size: 12px;
      font-weight: 400;
    }
    .pop-text {
      color: rgba(56, 56, 56, 1);
      font-size: 16px;
      font-weight: 400;
    }
    .pop-input {
      margin-top: 21px;
      width: 205px;
      height: 42px;
      color: #000;
      font-size: 20px;
      font-weight: 400;
      background-color: rgba(229, 229, 229, 1);
      border-radius: 2px;
      border: none;
      text-align: center;
    }
    .pop-button {
      margin-top: 26px;
      width: 205px;
      height: 42px;
      background-color: rgba(9, 97, 245, 1);
      border-radius: 2px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
    .pop-loading {
      margin-top: 1.5rem;
    }
    .pop-img--1 {
      position: absolute;
      top: 4.875rem;
      left: 5.75rem;
      width: 0.75rem;
    }
    .pop-img--2 {
      position: absolute;
      top: 3.125rem;
      left: 8.75rem;
      width: 1.562rem;
    }
    .pop-img--3 {
      position: absolute;
      top: 2.562rem;
      left: 7.187rem;
      width: 1.562rem;
    }
    .pop-img--4 {
      position: absolute;
      top: 5.625rem;
      left: 3.375rem;
      width: 0.5rem;
    }
    .pop-img--5 {
      position: absolute;
      top: 6.75rem;
      left: 16.437rem;
      width: 0.75rem;
    }
    .pop-img--6 {
      position: absolute;
      top: 8.875rem;
      left: 2.5rem;
      width: 1.125rem;
    }
    .pop-img--7 {
      position: absolute;
      top: 3.562rem;
      left: 16.437rem;
      width: 1.125rem;
    }
    .pop-img--8 {
      position: absolute;
      top: 13.5rem;
      left: 2.5rem;
      width: 0.875rem;
    }
    .pop-img--9 {
      position: absolute;
      top: 8rem;
      left: 19.062rem;
      width: 0.875rem;
    }
  }
}

@primary-color: #1DA57A;