.finish-study-container {
  overflow-y: overlay;
  padding: 7px 26px 0;
  height: calc(100vh - 105px);
  height: calc(100vh - 105px - env(safe-area-inset-bottom));
  overflow-y: overlay;
  box-sizing: border-box;
  .study-item {
    padding: 12px 6px 20px 6px;
    &-top {
      color: #808080;
      font-size: 13px;
      line-height: 19px;
      &-id {
        margin-right: 25px;
      }
    }
    &-content {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 109px;
      .content-pic {
        flex-shrink: 0;
        width: 194px;
        height: 109px;
        object-fit: cover;
        border-radius: 8px;
      }
      .content-desc {
        flex: 1;
        margin-left: 38px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .common-desc {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .desc-series {
          font-size: 13px;
          color: #ff8d1a;
          line-height: 19px;
          .common-desc();
        }
        .desc-books {
          margin-top: 7px;
          font-size: 16px;
          color: #202244;
          line-height: 19px;
          .common-desc();
        }
        .desc-grades {
          margin-top: 5px;
          font-size: 13px;
          color: #666666;
          line-height: 19px;
          .common-desc();
        }
        .desc-materials {
          margin-top: 5px;
          font-size: 13px;
          color: #666666;
          line-height: 19px;
        }
      }
      .desc-buttons {
        margin-top: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-report {
          padding: 4px;
          font-size: 13px;
          color: #00baad;
          background-color: rgba(67, 207, 124, 0.26);
          border-radius: 5px;
        }
        &-record {
          margin-left: 8px;
          padding: 4px;
          font-size: 13px;
          color: #7948ea;
          background-color: rgba(121, 72, 234, 0.16);
          border-radius: 5px;
        }
      }
    }
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .bottom-text {
    margin-bottom: 80px;
    width: 100%;
    height: 40px;
    line-height: 30px;
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
  .empty-block {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    border-radius: 18px;
    background: linear-gradient(270deg, #f6f8ff, #c8d0ff);
    box-sizing: border-box;
    &-title {
      color: #333;
      font-size: 16px;
    }
    &-button {
      margin-top: 15px;
      padding: 6px 14px;
      color: #fff;
      font-size: 13px;
      border-radius: 6px;
      background: linear-gradient(90deg, #5872ff, #2552ff);
    }
  }
}

@primary-color: #1DA57A;