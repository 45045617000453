.my-exchange-container {
  background: linear-gradient(0, #f4f6ff 0%, #dbdfff 100%);
  img {
    user-select: none;
  }
  display: flex;
  flex-direction: column;
  height: 100vh;
  .goods-list {
    padding: 4px 18px;
    flex: 1;
    overflow-y: overlay;
    &-item {
      padding: 13px;
      margin-bottom: 9px;
      background-color: #ffffff;
      border-radius: 13px;
      .item-content {
        display: flex;
        &-left {
          flex-shrink: 0;
          width: 97px;
          height: 97px;
          object-fit: contain;
          border-radius: 11px;
        }
        &-right {
          margin-left: 9px;
          .right-title {
            margin-top: 4px;
            color: #333333;
            font-size: 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
          .right-text {
            margin-top: 4px;
            color: #999999;
            font-size: 13px;
          }
        }
      }

      .item-status {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
          font-size: 15px;
          &--status1 {
            color: #ff8700;
          }
          &--status2 {
            color: #1955ff;
          }
          &--status3 {
            color: #999999;
          }
        }
        &-button {
          width: 83px;
          height: 31px;
          border-radius: 16px;
          border: 1px solid #1955ff;
          text-align: center;
          line-height: 31px;
          color: #1955ff;
          font-size: 14px;
          background-color: #fff;
          &--disabled {
            color: #999999;
            border-color: #999999;
          }
        }
      }
    }
    &-bottom {
      width: 100%;
      height: 40px;
      line-height: 30px;
      color: #999999;
      font-size: 13px;
      text-align: center;
    }
  }
  .empty-container {
    margin-top: 50%;
    .empty-icon {
      width: 140px;
    }
    .empty-text {
      margin: 0 auto;
      width: 80%;
    }
  }
}

@primary-color: #1DA57A;