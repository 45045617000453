.countdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 20px;
  width: 55px;
  height: 62.3px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../../images/game/clock.png");
  color: #009dce;
  opacity: 0;
  .text {
    margin-top: 8px;
    font-weight: bold;
    line-height: 23px;
    &-number {
      font-size: 23px;
    }
    &-unit {
      font-size: 17px;
    }
  }
  &--show {
    opacity: 1;
    transition: 0.5s all ease-in;
  }
  &--hide {
    opacity: 0;
    transition: 0.5s all ease-out;
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    top: 5px;
    left: 10px;
    width: 40px;
    height: 52px;
    .text {
      margin-top: 1px;
      font-weight: bold;
      line-height: 23px;
      &-number {
        font-size: 16px;
      }
      &-unit {
        font-size: 14px;
      }
    }
  }
}

@primary-color: #1DA57A;