.content-title {
  padding: 15px 15px 0 15px;
}
.content-container {
  padding: 0 15px 15px 15px;
}
.bottom-container{
  width: 100%;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  color:#202244;
}

@primary-color: #1DA57A;