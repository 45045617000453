.container {
  width: 100%;
  height: 100%;
  background:linear-gradient( 360deg, #ffffff 0%, #F4F6FF 100%);

  .topBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 25%;
    width: 100%;
    background: linear-gradient( 360deg, #F4F6FF 0%, #CCD2FF 100%);
    z-index: 1;
  }
  .banner{
    position: fixed;
    width: 90%;
    height: 12%;
    z-index: 2;
    margin: 0 auto;
    top: 12%;
    left: 0;
    right: 0;
    background-image: url("../../../images/mine/ic_mine_invite_banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .rule{
    position: fixed;
    width: 85%;
    padding: 10px 15px 10px 15px;
    margin: 0 auto;
    top: 26%;
    left: 0;
    right: 0;
    background:linear-gradient( 360deg, #ffffff 0%, #CCD2FF 100%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      color: #3D3D3D;
      margin: 5px;
      span{
        color: #1955FF;
      }
    }
    .content{
      color: #3D3D3D;
    }
  }
  .myRecord{
    position: fixed;
    margin: 0 auto;
    top: 61%;
    bottom: 60px;
    width: 150px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    left: 0;
    right: 0;
    background-color: #D1D6FF;
    border-radius: 10px;
    color: #383838;
    font-weight: bold;
    font-size: 20px;
  }
  .recordList{
    background-color: #fff;
    position: fixed;
    height: 300px;
    //top: 67%;
    top: 60%;
    left: 0;
    right: 0;
    overflow-y: scroll;

    .recordItem{
      background-color: #F6F7FF;
      height: 70px;
      border-radius: 10px;
      margin: 10px 15px 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 15px;

      .avatar{
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }

      .center{
        width: 230px;
        display: flex;
        flex-direction: row;
        .nick{
          width: 130px;
        }
        .phone{
          width: 100px;
          color: red;
        }
      }

      .status{
        color: #193BDB;
      }
    }
  }
  .inviteBtn{
    position: fixed;
    margin: 0 auto;
    bottom: 10px;
    width: 95%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    left: 0;
    right: 0;
    background-color: #2A82E4;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }
  .report-share {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background-color: rgba(32, 34, 68, 0.8);
    &-top {
      position: relative;
      margin-top: 10vh;
      display: block;
      img {
        display: block;
        margin: 0 auto;
        height: 55vh;
        border-radius: 15px;
      }
      .close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
      }
    }
    &-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20vh;
      background-color: #fff;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      .button-list {
        margin-top: 3vh;
        padding: 0 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 6vh;
            height: 6vh;
          }
          .text {
            margin-top: 9px;
            color: #000000;
            font-size: 12px;
            text-align: center;
          }
          .save {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6vh;
            height: 6vh;
            border-radius: 5vh;
            background-color: rgba(255, 148, 71, 1);
            &-icon {
              width: 3vh;
              height: 3vh;
            }
          }
        }
      }
      .cancel-button {
        margin-top: 3vh;
        color: rgba(143, 143, 143, 1);
        font-size: 14px;
        text-align: center;
      }
    }
  }

  /* Pad横屏时的样式 */
  @media screen and (orientation: landscape) and (min-width: 768px) and (max-width: 3048px) {
    .banner{
      top:14%;
      width: 95%;
      height: 24%;
      background-image: url("../../../images/mine/ic_mine_invite_banner_pad.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .rule{
      top: 39%;
      width: 92%;
      padding: 5px 15px 0 15px;
      .content{
        height: 85px;
        overflow-y: auto;
      }
    }
    .recordList{
      height: 190px;
    }
  }

  /* Pad竖屏时的样式 */
  @media screen and (orientation: portrait) and (min-width: 768px) and (max-width: 2032px) {
    .banner{
      width: 95%;
      height: 15%;
      background-image: url("../../../images/mine/ic_mine_invite_banner_pad.png");
    }
    .rule{
      width: 90%;
      top: 28%;
    }
    .recordList{
      top: 58%;
      height: 280px;
    }
  }
}


@primary-color: #1DA57A;