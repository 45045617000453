.record_page {
  overflow-y: overlay;
  height: 100vh;
  padding: 0 10px;
  background-color: rgb(245, 245, 245);
  .rv-nav-bar {
    background-color: rgb(245, 245, 245);
  }
  h3 {
    text-align: center;
  }
  .rv-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
    .teacher_info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      .rv-card {
        min-width: 50px;
      }
      img {
        margin-left: 15px;
        width: 55px;
        height: 55px;
      }
      .photo_style {
        display: flex;
        flex-direction: column;
        img {
          margin-left: 15px;
          width: 300px;
          height: 250px;
        }
        div {
          font-size: 10px;
          margin: 15px 0 0 15px;
        }
      }
      .contont {
        display: flex;
        .left_mark {
          width: 0;
          height: 0;
          border: 8px solid;
          margin-top: 10px;
          border-color: rgb(245, 245, 245) rgb(255, 255, 255) rgb(245, 245, 245)
            rgb(245, 245, 245);
        }
      }
      margin-bottom: 10px;
    }
    .my_info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      .rv-card {
        min-width: 50px;
        background-color: rgb(149, 216, 105);
        .rv-card-body {
          text-align: right;
        }
      }
      img {
        margin: 0 15px 0 0;
        width: 55px;
        height: 55px;
      }
      .contont {
        display: flex;
        .left_mark {
          width: 0;
          height: 0;
          border: 8px solid;
          margin-top: 10px;
          margin-left: -1px;
          border-color: rgb(245, 245, 245) rgb(245, 245, 245) rgb(245, 245, 245)
            rgb(149, 216, 105);
        }
      }
      margin-bottom: 10px;
    }
  }
  .teacher_info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .rv-card {
      min-width: 50px;
    }
    img {
      margin-left: 15px;
      width: 55px;
      height: 55px;
    }
    .photo_style {
      display: flex;
      flex-direction: column;
      img {
        margin-left: 15px;
        width: 300px;
        height: 250px;
      }
      div {
        font-size: 10px;
        margin: 15px 0 0 15px;
      }
    }
    .contont {
      display: flex;
      .left_mark {
        width: 0;
        height: 0;
        border: 8px solid;
        margin-top: 10px;
        border-color: rgb(245, 245, 245) rgb(255, 255, 255) rgb(245, 245, 245)
          rgb(245, 245, 245);
      }
    }
    margin-bottom: 10px;
  }
  .my_info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    .rv-card {
      min-width: 50px;
      background-color: rgb(149, 216, 105);
      .rv-card-body {
        text-align: right;
      }
    }
    img {
      margin: 0 15px 0 0;
      width: 55px;
      height: 55px;
    }
    .contont {
      display: flex;
      .left_mark {
        width: 0;
        height: 0;
        border: 8px solid;
        margin-top: 10px;
        margin-left: -1px;
        border-color: rgb(245, 245, 245) rgb(245, 245, 245) rgb(245, 245, 245)
          rgb(149, 216, 105);
      }
    }
    margin-bottom: 10px;
  }
  .bottom {
    width: 100%;
    height: 40px;
    line-height: 30px;
    color: #999999;
    font-size: 13px;
    text-align: center;
  }
}

@primary-color: #1DA57A;