.level-container {
  .tab-container {
    margin-top: 10px;
    :global(.rv-tabs__wrap) {
      padding: 0 26px;
      z-index: 100;
      background-color: #fff;
      :global(.rv-tabs__nav) {
        padding: 0;
        overflow-x: overlay;
        :global(.rv-tab) {
          flex: none;
          padding: 0 0 4px 0;
          margin-right: 18px;
          :global(.rv-tab__text) {
            padding: 2px 0;
            font-size: 20px;
            color: #979797;
          }
        }
        :global(.rv-tab--active) {
          :global(.rv-tab__text) {
            color: var(--color2);
            font-size: 24px !important;
            transform: translateY(-0.1rem);
          }
        }
        :global(.rv-tab--active::after) {
          content: "";
          width: 15px;
          height: 7px;
          background: #1955ff;
          border-radius: 4px;
          position: absolute;
          background-color: var(--primyColor1);
          left: 0;
          bottom: 0px;
        }
        :global(.rv-tabs__line) {
          background-color: transparent;
        }
      }
    }
    .level-data {
      padding: 0 26px;
      margin-top: 22px;
      &-item {
        margin-bottom: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-left {
          position: relative;
          width: 280px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &-pic {
            flex-shrink: 0;
            width: 66px;
            border-radius: 8px;
          }
          &-lock {
            position: absolute;
            top: 0;
            left: 0;
            width: 66px;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            .lock-img {
              width: 35px;
            }
            .lock-text {
              margin-top: 6px;
              color: #fff;
              font-size: 13px;
            }
          }
          &-content {
            margin-left: 18px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .content-title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 23px;
              color: #202244;
            }
            .content-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              font-size: 14px;
              line-height: 20px;
              color: #666666;
            }
          }
        }
        .item-right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-shrink: 0;
          &-favorite {
            margin-left: 13px;
            width: 40px;
          }
          &-pro {
            width: 40px;
          }
        }
      }
      .bottom-text {
        width: 100%;
        height: 40px;
        line-height: 30px;
        color: #999999;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

.pop-container--lock {
  img {
    user-select: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .lock-wrapper {
    width: 317px;
    height: 371px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("/images/image/ic_locked_pop.png");
    &-title {
      margin-top: 70px;
      margin-left: 50px;
      color: rgba(0, 107, 152, 1);
      font-size: 26px;
      font-weight: bold;
    }
    &-text {
      margin-top: 40px;
      margin-left: 30px;
      padding: 34px 0;
      width: 252px;
      color: rgba(0, 107, 152, 1);
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      background-color: rgba(40, 168, 222, 0.1);
      border-radius: 6px;
    }
  }
  .lock-close {
    margin-top: 31px;
    width: 25px;
    height: 25px;
  }
  .lock-confirm {
    margin-top: 33px;
    margin-left: 80px;
    width: 154px;
    height: 49px;
    background-image: url("/images/mine/recommend-officer/button_back.png");
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 49px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
}

@primary-color: #1DA57A;